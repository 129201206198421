.Banner-module--banner--dba7f {
    background-image: url(https://invozone.com/static/bannerBg-35e14f009fb40116fc4a64816eadf172.svg);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 65px 0 93px;
    position: relative;
    width: 100%;
}

.Banner-module--banner--dba7f .Banner-module--heading--516dc {
    margin-top: 40px;
}

.Banner-module--banner--dba7f .Banner-module--heading--516dc .Banner-module--bannerHeading--d620f {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 616px;
}

.Banner-module--banner--dba7f .Banner-module--heading--516dc p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    max-width: 568px;
    padding-top: 5px;
}

.Banner-module--bannerBtn--0c9cf {
    padding-top: 36px;
}

.Banner-module--btn_white2_border--218ff {
    background-color: white!important;
    border: none;
    border-radius: 50rem;
    color: #000!important;
    display: inline-block;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Banner-module--bnrImg--5db6f {
    padding-left: 100px;
}

.Services-module--onDemandmainWork--8db37 {
    background-image: url(https://invozone.com/static/servicesBg-92c767905149b4240abd385e4a08827a.svg);
    margin-bottom: 50px;
    padding-bottom: 100px;
}

.Services-module--heading--76cde {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    padding-top: 88px;
    text-align: center;
}

.Services-module--description--8d692 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    padding-bottom: 36px;
    padding-top: 25px;
    position: relative;
    text-align: left;
    text-align: center;
    z-index: 1;
}

.Services-module--SliderWidth--86564 {
    margin: auto;
    width: 100%;
}

.Services-module--cardWrapper--35421 .Services-module--card--2c98e {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    height: 378px;
    padding: 36px;
}

.Services-module--cardWrapper--35421 .Services-module--card--2c98e h3 {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 8px;
    padding-top: 12px;
}

.Services-module--cardWrapper--35421 .Services-module--card--2c98e p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    line-height: 28px;
    margin: 0;
    text-align: left;
}

.TopFeature-module--featuremainContainer--388a5 {
    padding: 70px 0;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca {
    background-repeat: no-repeat;
    background-size: 45% 92%;
    margin: 0!important;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 {
    margin: 0!important;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--sticky--c9970 {
    position: -webkit-sticky;
    position: sticky;
    top: 12%;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--benefitsHead--f9f40 {
    font-size: 37px;
    font-weight: 700;
    line-height: 54px;
    max-width: 320px;
    padding-top: 15px;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--mainDesc--4bec0 {
    color: #212529;
    font-size: 16px;
    font-size: 400;
    line-height: 26px;
    padding-top: 15px;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--cardWrapper--66d5b {
    position: relative;
    z-index: 99;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--cardWrapper--66d5b .TopFeature-module--card--094b2 {
    background-color: #fbfbfb;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    padding: 30px 31px 23px 34px;
    text-align: left;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--cardWrapper--66d5b .TopFeature-module--card--094b2 img {
    margin-bottom: 18px;
    max-width: 60px;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--cardWrapper--66d5b .TopFeature-module--card--094b2 h3 {
    cursor: default;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 16px;
}

.TopFeature-module--featuremainContainer--388a5 .TopFeature-module--mainRow--720ca .TopFeature-module--innerRow--b9cd6 .TopFeature-module--cardWrapper--66d5b .TopFeature-module--card--094b2 p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
    text-align: left;
}

.Idea-module--mainContainer--29fec {
    background-image: url(https://invozone.com/static/idea-414be5369c9e42afac847febe1dd771b.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;
    margin-top: 20px;
    padding: 125px 0 65px 80px;
    margin-bottom: 50px;
}

.Idea-module--mainContainer--29fec .Idea-module--mainRow--98e4f {
    margin: 0!important;
}

.Idea-module--mainContainer--29fec .Idea-module--mainHead--b1067 {
    font-size: 39px;
    font-weight: 700;
}

.Idea-module--mainContainer--29fec .Idea-module--description--54e3a {
    font-size: 20px;
    font-weight: 400;
    max-width: 650px;
    padding-top: 10px;
}

.Idea-module--mainContainer--29fec .Idea-module--idea_btn--9f400 {
    background-color: #000!important;
    border: none;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 36px;
    overflow: hidden;
    padding: 15px 37px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Industries-module--servicesDemand--dfaaf {
    background: linear-gradient(181.13deg,#ededee -76.76%,hsla(240,3%,93%,0) 112.95%);
    padding: 100px 0 70px;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--heading--fcbab {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    text-align: center;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--description--01686 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--cardWrapper--cfa63 {
    justify-content: center;
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--cardWrapper--cfa63 .Industries-module--card--f254a {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0,0,0,.04);
    padding: 34px 30px;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--cardWrapper--cfa63 .Industries-module--card--f254a img {
    margin-bottom: 18px;
    max-width: 60px;
}

.Industries-module--servicesDemand--dfaaf .Industries-module--cardWrapper--cfa63 .Industries-module--card--f254a h3 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0;
}

