.Banner-module--section--f2ad6{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 96px;
    background-color: #f2f2f2;
    background-image: url('/src/images/dedicated.png');
    background-position: 50%;
    color: #fff;
    padding-top: 150px;
}

.main-banner-heading-55 {
    font-size: 55px;
}

.WhyDDevs-module--section--30950 {
    background-color: #f2f2f2;
    padding-bottom: 96px;
    padding-top: 96px;
}

.IdealTeam-module--section--de89d {
    background-image: url('/src/images/teammodel.png');
    padding-top: 96px;
}

.IdealTeam-module--section--de89d{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 96px;
}

.IdealTeam-module--card--53c06 {
    padding: 30px;
    position: relative;
    z-index: 1;
}

.IdealTeam-module--card--53c06 img {
    left: -18px;
    position: absolute;
    top: -20px;
    z-index: -1;
}

h1{
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
}

.Technologies-module--section--14f29 {
    background-color: #fff8f0;
    padding-bottom: 96px;
    padding-top: 96px;
}

.Technologies-module--technologyIcon--fe1fd {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
}

.Technologies-module--section--14f29 img {
    width: 60px;
}

.Advantages-module--section--50284 {
    background-color: #f2f2f2;
    padding-bottom: 96px;
    padding-top: 96px;
}

p.card-text {
    font-size: 18px;
    min-height: 86px;
}

.MidHero-module--section--07545 {
    background: #2276ff;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding-bottom: 16px;
    padding-top: 16px;
}




@media (min-width: 992px){
.ml-lg-0, .mx-lg-0 {
    margin-left: 0!important;
}
}
