.Banner-module--careers--c8ceb {
    background-color: #f8f9fa;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 130px 0;
}

.Banner-module--careers--c8ceb h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
}

.Banner-module--careers--c8ceb p {
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
}

.Banner-module--careers--c8ceb .Banner-module--bannerBtn--6c461 {
    padding-top: 12px;
}

.Banner-module--careers--c8ceb .Banner-module--bannerBtn--6c461 .Banner-module--btn--94e64 {
    background-color: rgb(30 19 133);
    border: none;
    border-radius: 50rem;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    overflow: hidden;
    padding: 14px 30px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.CeoMessage-module--CeoMessage--4d1e2 {
    background: #fafafa;
    padding: 100px 0 40px;
    position: relative;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a {
    background: #fff;
    border-radius: 16px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--text--65aef {
    padding: 54px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--text--65aef h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    max-width: 544px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--text--65aef p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 24px 0;
    max-width: 707px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--cta--62b0f {
    background: url(https://invozone.com/static/furqan_aziz_bg-1b73f420ea7198cda854b4205b016dcc.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    color: #fff;
    height: 790px;
    margin: 10px;
    max-width: 431px;
    text-align: center;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--cta--62b0f img {
    border-radius: 20px 20px 0px 0px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--cta--62b0f .CeoMessage-module--Furqan--6d9af {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-top: 36px;
    max-width: unset;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--cta--62b0f p {
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 211px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--socialBtn--a6356 {
    align-items: center;
    padding-top: 18px;
    width: 100%;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--socialBtn--a6356 a {
    background-color: #fff;
    border: 0;
    border-radius: 50%;
    color: #2176ff;
    font-size: 16px;
    height: 32px;
    line-height: 26px;
    margin: 0 5px;
    padding: 2px;
    width: 32px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 {
    padding: 60px 0;
}

@media screen and (min-width: 1280px) {
    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirHeading2--9d27e {
        display: none;
    }
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirHeading2--9d27e {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    padding-bottom: 30px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText--1cc65 {
    margin: 20px 0;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText--1cc65 .CeoMessage-module--inspirHeading--3ac98 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    padding-bottom: 24px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText--1cc65 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
}

.CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText2--73140 {
    padding-top: 60px;
}

.Sneak-module--sneak--9e507 {
    background: url(https://invozone.com/static/neakBg-4573b17d0aea5111cb5890cc157e98c4.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
}

.Sneak-module--sneak--9e507 .Sneak-module--sneakText--0df29 h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    max-width: 470;
}

.Sneak-module--sneak--9e507 .Sneak-module--sneakText--0df29 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    max-width: 434px;
}

.Sneak-module--SliderWidth--25a60 {
    width: 100%;
}

.Sneak-module--iconContainerLeft--e834c {
    bottom: -15px;
    left: -76%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
}

.Sneak-module--portfolioArrowIconCover--fa828,
.Sneak-module--portfolioArrowRightIconCover--e1ba5 {
    align-items: center;
    border-radius: 24px;
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, .1);
    color: #217cfd;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
}

.Sneak-module--portfolioArrowIcon--fa626 {
    font-size: 22px;
}

.Sneak-module--sneak--9e507 .Sneak-module--sliderImg--09581 {
    width: 100%;
}

.Sneak-module--iconContainer--646eb {
    bottom: -15px;
    display: inline;
    left: -77%;
    position: absolute;
    transform: translateY(-50%);
}

.Sneak-module--portfolioArrowRightIconCover--e1ba5 {
    background: #fff;
    margin-left: 70px;
}

@media (max-width: 767px) {
    .Banner-module--careers--c8ceb {
        background-repeat: no-repeat;
        background-size: cover;
        height: 670px;
    }

}

@media (max-width: 1280px) {
    .Banner-module--careers--c8ceb .Banner-module--bannrtText--d1da1 {
        text-align: center;
    }

    .CeoMessage-module--CeoMessage--4d1e2 {
        padding: 60px 0 0;
    }

    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a {
        background: unset;
    }

    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--text--65aef {
        padding: unset;
    }

    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--message--6449a .CeoMessage-module--cta--62b0f {
        margin: unset;
        margin-bottom: 45px;
    }

    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText--1cc65 .CeoMessage-module--inspirHeading--3ac98 {
        display: none;
    }

    .CeoMessage-module--CeoMessage--4d1e2 .CeoMessage-module--inspiring--ba305 .CeoMessage-module--inspirinText--1cc65 .CeoMessage-module--inspirHeading--3ac98 {
        display: none;
    }

}

@media (max-width: 1199px) {
    .performanceimg{
        display: none;
    }
}
