* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Encode Sans Expanded', sans-serif; */
}

body {
  background-color: #fff;
  color: #212529;
  font-family: Public Sans;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  text-align: left !important;
}