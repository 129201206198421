.Footer-module--footer--a2e01 {
    background-image: url('/src/images/footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

.Footer-module--footer--a2e01 a {
    color: #fff;
}

a {
    background-color: transparent;
    color: #2176ff;
    text-decoration: none !important;
}

.Footer-module--divider--4c242 {
    border-bottom: 1px solid hsla(0, 0%, 100%, .267);
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.newadjustment {
    margin-top: -9px;
}

.h5.newsletter {
    margin-bottom: 20px;
}

.form-control {
    border: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

input[type="text"] {
    border-radius: 0.75rem;
}

.input-group-append {
    margin-left: -1px !important;
}

.input-group-append,
.input-group-prepend {
    display: flex;
}

.nl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    margin: 0px !important;
}

.Footer-module--followUs--7a484 {
    align-items: center;
    display: flex;
    gap: 30px;
    margin-top: 90px;
}

.Footer-module--followUs--7a484 a {
    background: #fff !important;
    border: #fff !important;
    border-radius: 50%;
    color: #2276ff !important;
    font-size: 18px;
    height: 36px;
    padding: 2.75px;
    width: 36px;
}

.Footer-module--footerNav--88d42 {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    color: #f8f9fa !important;
    font-weight: 600;
}


@media screen and (max-width: 992px) {
    .Footer-module--footer--a2e01 {
        background-position: 50%;
    }
}

@media screen and (max-width: 768px) {
    .reown{
        right: 30px !important;
    }

    .span1{
        display: none;
    }

    .display{
        display: block;
    }
    
    .floating{
        float: none !important;
    }

    .Footer-module--footerNav--88d42 {
        margin-top: 1rem;
    }

    .Footer-module--footerNav--88d42 a {
        display: block;
        padding: 0.75rem 0;
    }
}

@media (min-width: 768px) {
    .Footer-module--footerNav--88d42 a:not(:last-child):after {
        border-right: 2px solid #fff;
        content: "";
        margin-left: 12px;
        margin-right: 12px;
    }
}