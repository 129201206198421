section:first-child {
    margin-top: -75px;
    padding-top: 150px!important;
}

.pricingCard-module--cardContainer--63616 {
    background: #fff;
    border-radius: 8px;
    box-shadow: 19px 14px 84px 0 rgba(0,0,0,.07);
    box-sizing: border-box;
    justify-content: center;
    margin-bottom: 30px;
}

.pricingCard-module--headerContainer--b5200 {
    background-color: #0fb3d8;
    border-radius: 8px 8px 0 0;
    color: #fff;
    display: flex;
    height: 173px;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.pricingCard-module--bodyContainer--59c66 {
    border-bottom: 1px dashed #d0d0d0;
    min-height: 530px;
    padding: 20px;
}

.pricingCard-module--tickText--3e912 {
    padding-top: 1.5px;
}

.pricingCard-module--questionContainer--ef805 {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 300px;
    padding: 0 20px;
}

.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
}

.css-7pg0cj-a11yText {
    z-index: 9999;
    border: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    white-space: nowrap;
}

.css-1gaj0j4-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 43px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    float: right;
    outline: 0px !important;
}

.css-1hwfws3 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-1wa3eu0-placeholder {
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}

.css-1g6gooi {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-xzu46z-indicatorContainer {
    color: rgb(0, 0, 0);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}

.css-8mmkcg {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}

.btn_black_border{
    border: 1px solid var(--primary)!important;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 20px;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
}

.pricingCard-module--coloredArea--37a50 {
    background-color: #0fb3d8;
    border-radius: 0 0 8px 8px;
    height: 14px;
}


@media (min-width: 1200px){
.col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
}
}