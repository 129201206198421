.Banner-module--section--e5bdb {
    background-color: #f8f8f8;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
    padding-bottom: 150px;
    padding-top: 0;
    width: 100%;
    margin-top: -75px !important;
    padding-top: 150px!important;
}

.Banner-module--section--e5bdb h1 {
    font-size: 3.4375rem;
}

.Banner-module--section--e5bdb .Banner-module--typingH1--e650b {
    cursor: default;
    font-size: 3.4375rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

