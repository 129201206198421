.Banner-module--section--1f46d {
    background-color: #f8f8f8;
    background-image: url('/src/images/banner.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 632px;
    padding-bottom: 124px;
    padding-top: 124px;
}

.Banner-module--mainHeading--13b6e {
    font-size: 55px;
    padding-bottom: 20px;
}

.font-weight-normal {
    font-weight: 400!important;
}

.ImageCard-module--card--83478 {
    align-items: center;
    display: flex;
    position: relative;
}

.ImageCard-module--card--83478 .ImageCard-module--content--7bd66 {
    color: #fff;
    left: 0;
    margin-left: 8rem;
    margin-top: 8rem;
    position: absolute;
    top: 0;
    width: 40%;
}

.ImageCard-module--card--83478:not(:first-child) {
    margin-top: -8rem;
}

@media (min-width: 992px){
.d-none, .py-4.col {
    padding: 50px!important;
}
}

@media (min-width: 1200px){
.container {
    max-width: 1280px;
}
}

@media (min-width: 1400px){
.container {
    max-width: 1320px;
}
}