.WorkPortfolio-module--portfolioContnet--87caa {
    text-align: left;
}

.WorkPortfolio h2 {
    cursor: default;
}

.WorkPortfolio-module--iconContainerLeft--4d499 {
    position: absolute;
    right: 6%;
    top: 30px;
    z-index: 1;
}

.WorkPortfolio-module--portfolioArrowIconCover--12a28 {
    align-items: center;
    background-color: rgb(30 19 133);
    border-radius: 30px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
    color: #fefefe;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
    z-index: 1;
}

.WorkPortfolio-module--portfolioArrowIconCover--12a28 img {
    filter: brightness(0) invert(1);
}
.WorkPortfolio-module--portfolioArrowIcon--e5a44 {
    width: 25px;
}

.simple-font-weight {
    font-weight: 600!important;
}

.WorkPortfolio-module--iconContainer--37a96 {
    display: inline;
    position: absolute;
    right: 0;
    top: 30px;
}

.WorkPortfolio-module--portfolioArrowRightIconCover--e3136 {
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 30px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
    color: #000;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-left: 70px;
    width: 50px;
}

.button__bar {
    border-radius: 40px;
    display: inline-block;
    left: 57%;
    margin: auto;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 50px;
    vertical-align: middle;
    z-index: 0;
}

.button__bar li {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0 3px;
}

.button__bar li.slick-active button {
    background-color: #2176ff;
}
.button__bar li button {
    background: #e0e0e0;
    border: none;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    padding: 0;
    width: 10px;
}

img{
    max-width: 100%;
}

.slick-slide img {
    display: block;
}

.slick-track:before{
    content: "";
    display: table;
}

.WorkPortfolio-module--portContent--38182 {
    margin: 0 auto;
    padding-bottom: 30px;
    text-align: center;
}

.WorkPortfolio h2 {
    cursor: default;
}

.WorkPortfolio-module--portContent--38182 p {
    margin-bottom: 0;
}

