.card {
    background-color: transparent!important;
    border: none!important;
}
.PostCard-module--card--1bf0b {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    max-width: 409px;
    min-height: 502px;
    overflow: auto;
    transition: .6s;
}

.card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
}

.PostCard-module--containerA--a02e0 {
    background-color: #fff;
    padding: 29px 12px;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.PostCard-module--containerA--a02e0 .PostCard-module--info--28cf8 {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.PostCard-module--containerA--a02e0 .PostCard-module--info--28cf8 .PostCard-module--category--1da2b {
    align-items: center;
    border: 1px solid #2176ff;
    border-radius: 2px;
    color: #2176ff;
    display: flex;
    font-family: Public Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    justify-content: center;
    line-height: 138.9%;
    min-height: 28px;
    min-width: 105px;
    padding: 5px;
}
.PostCard-module--containerA--a02e0 a {
    color: inherit;
    text-decoration: none;
    width: auto;
}

.PostCard-module--containerA--a02e0 .PostCard-module--info--28cf8 .PostCard-module--authurName--1a5da {
    font-weight: 700;
    max-width: 220px;
    min-width: 154px;
    position: relative;
}