.Banner-module--banner--f6532 {
    background-image: url(https://invozone.com/static/bannerBg-35e14f009fb40116fc4a64816eadf172.svg);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 104px 0;
    position: relative;
    width: 100%;
}

.Banner-module--banner--f6532 .Banner-module--heading--9593d {
    margin-top: 40px;
}

.Banner-module--banner--f6532 .Banner-module--heading--9593d .Banner-module--bannerHeading--6a2e1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 616px;
}

.Banner-module--banner--f6532 .Banner-module--heading--9593d p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    max-width: 568px;
    padding-top: 5px;
}

.Banner-module--bannerBtn--0774f {
    padding-top: 36px;
}

.Banner-module--btn_white2_border--fc6d3 {
    background-color: white!important;
    border: none;
    border-radius: 50rem;
    color: #000!important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

@media (min-width: 767px){
.Banner-module--banner--f6532 .Banner-module--ColLeft--a7418 .Banner-module--mobImg--db219 {
    display: none;
}
}

.Explore-module--exploreServices--67104 {
    padding: 100px 0;
    position: relative;
}

.Explore-module--exploreServices--67104 .Explore-module--heading--7775b {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    text-align: center;
}

.Explore-module--exploreServices--67104 .Explore-module--description--72f18 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.Explore-module--cardBg--6b09b {
    background: #f7f7f7;
    border-radius: 24px;
    bottom: 71px;
    left: 0;
    margin: 0 auto;
    max-width: 968px;
    min-height: 1070px;
    position: absolute;
    right: 0;
}

.Explore-module--exploreServices--67104 .Explore-module--cardWrapper--9c789 {
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

.Explore-module--exploreServices--67104 .Explore-module--cardWrapper--9c789 .Explore-module--card--6009a {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0,0,0,.04);
    padding: 36px 31px 23px 34px;
}

.Explore-module--exploreServices--67104 .Explore-module--cardWrapper--9c789 .Explore-module--card--6009a img {
    margin-bottom: 18px;
    max-width: 60px;
}

.Explore-module--exploreServices--67104 .Explore-module--cardWrapper--9c789 .Explore-module--card--6009a h3 {
    cursor: default;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 16px;
}

.Explore-module--exploreServices--67104 .Explore-module--cardWrapper--9c789 .Explore-module--card--6009a p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
    min-height: 130px;
    text-align: left;
}

.Solutions-module--hireCyber--a4f4d {
    padding: 100px 0;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--hire--86235 h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 24px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--hire--86235 .Solutions-module--description--a063b {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--hire--86235 .Solutions-module--description--a063b {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--cards--3f77f {
    background: #fff;
    border-radius: 12px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--cards--3f77f .Solutions-module--talent--9b5ca {
    align-items: flex-start;
    display: flex;
    padding: 3px 0;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--cards--3f77f .Solutions-module--talent--9b5ca .Solutions-module--tick--1b4b7 {
    margin-top: 5px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--cards--3f77f .Solutions-module--talent--9b5ca .Solutions-module--text--ac319 {
    margin-left: 12px;
}

.Solutions-module--hireCyber--a4f4d .Solutions-module--cards--3f77f .Solutions-module--talent--9b5ca h3 {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.Solutions-module--teamBtn--9586f {
    margin-top: 30px;
}

.LatestFeatures-module--hireCyber--2fd84 {
    padding: 80px 0;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--hire--f97d4 h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 24px;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--hire--f97d4 .LatestFeatures-module--description--deda7 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 15px;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--cards--a4239 {
    background: #fff;
    border-radius: 12px;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--cards--a4239 .LatestFeatures-module--talent--593cf {
    align-items: flex-start;
    display: flex;
    padding: 3px 0;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--cards--a4239 .LatestFeatures-module--talent--593cf .LatestFeatures-module--tick--a33c0 {
    margin-top: 5px;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--cards--a4239 .LatestFeatures-module--talent--593cf .LatestFeatures-module--text--e3510 {
    margin-left: 12px;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--cards--a4239 .LatestFeatures-module--talent--593cf h3 {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.LatestFeatures-module--hireCyber--2fd84 .LatestFeatures-module--subTitle--5acdf {
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 24px 0 0;
    max-width: 450px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb {
    padding: 80px 0;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--hire--e268a h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 24px;
    padding-left: 20px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--hire--e268a .HireDevelopers-module--description--9c146 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 15px;
    padding-left: 20px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--cards--80fe2 {
    background: #fff;
    border-radius: 12px;
    padding-left: 20px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--cards--80fe2 .HireDevelopers-module--talent--389f4 {
    align-items: flex-start;
    display: flex;
    padding: 3px 0;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--cards--80fe2 .HireDevelopers-module--talent--389f4 .HireDevelopers-module--tick--f298d {
    margin-top: 5px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--cards--80fe2 .HireDevelopers-module--talent--389f4 .HireDevelopers-module--text--fdb6b {
    margin-left: 12px;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--cards--80fe2 .HireDevelopers-module--talent--389f4 h3 {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.HireDevelopers-module--hireCyberDeveloper--ebacb .HireDevelopers-module--bottomDes--193e3 {
    padding-left: 20px;
}


