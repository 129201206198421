.Banner-module--section--be768 {
    /* background-image: url(); */
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 15px solid rgb(30 19 133);
    color: #fff;
    padding: 200px 0 249px;
    position: relative;
    text-align: center;
    background-color: hsla(0, 0%, 97%, .7);
}

.Banner-module--section--be768 {
    color: black;
}

.Banner-module--evolve--97da2 {
    background: rgb(30 19 133);
    border: none;
    border-radius: 50rem;
    color: white;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    min-width: 100px;
    overflow: hidden;
    padding: 13px 26px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Banner-module--section--be768 h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin: 0 auto;
    max-width: 832px;
}

.Banner-module--section--be768 .Banner-module--paragraph--04cf7 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: 24px auto 0;
    max-width: 796px;
}

.Banner-module--futureBtn--587a8 {
    bottom: -62px;
    left: 0;
    position: absolute;
    right: 0;
}

.Banner-module--futureBtn--587a8 .Banner-module--future--b608f {
    background: rgb(30 19 133);
    border: none;
    border-radius: 50rem;
    color: white;
    display: inline-block;
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0;
    min-width: 100px;
    overflow: hidden;
    padding: 30px 140px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.WhyWork-module--hireCyber--ec470 {
    padding: 158px 0 80px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--hire--ea911 h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 24px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--hire--ea911 .WhyWork-module--description--90c97 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--cards--826a4 .WhyWork-module--talent--83ec5 {
    align-items: flex-start;
    display: flex;
    padding: 3px 0;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--cards--826a4 .WhyWork-module--talent--83ec5 .WhyWork-module--tick--d08ea {
    margin-top: 10px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--cards--826a4 .WhyWork-module--talent--83ec5 .WhyWork-module--text--d0c08 {
    margin-left: 12px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--cards--826a4 .WhyWork-module--talent--83ec5 p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    padding-top: 8px;
}

@media screen and (min-width: 767px) {
    .Banner-module--futureBtn2--15b81 {
        display: none;
    }
}

.Banner-module--futureBtn2--15b81 .Banner-module--future--b608f {
    background: rgb(13 113 218);
    border: none;
    color: white;
    display: inline-block;
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0;
    min-width: 100px;
    overflow: hidden;
    padding: 30px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.WhyWork-module--teamBtn--28036 {
    margin-top: 30px;
}

.WhyWork-module--hireCyber--ec470 .WhyWork-module--hireImg--6586d {
    text-align: center;
}

.Benefits-module--benefits--45c05 {
    background: #fafafa;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 100px;
}

.Benefits-module--benefits--45c05 .Benefits-module--dec--dfa26 {
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 60px;
    max-width: 676px;
}

.Benefits-module--flipbox--68471 {
    background-color: transparent;
    border-radius: 10px;
    perspective: 1000px;
}

.Benefits-module--flipboxinner--ece7b {
    display: flex;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;
    transition: transform .8s;
}

.Benefits-module--flipboxfront--13390 {
    border-radius: 10px;
    text-align: center;
}

.Benefits-module--flipboxfront--13390 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: #fff;
    height: 214px;
    width: 298px;
}

.Benefits-module--card--2ac53 {
    align-items: center;
    border: none;
    border-radius: 24px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    display: flex;
    height: 214px;
    justify-content: center;
    padding: 10px;
    width: 298px;
}

.Benefits-module--card--2ac53 h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.Benefits-module--flipboxback--703ef {
    align-items: center;
    background: #2176ff !important;
    border-radius: 10px;
    color: #fff;
    display: flex;
    transform: rotateX(180deg);
}

.Benefits-module--flipbackText--fd208 {
    align-items: baseline;
    display: flex;
}

.Benefits-module--flipbackText--fd208 p {
    margin: 0;
}

.Benefits-module--card--2ac53 p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
}

.Benefits-module--bannerBtn--35e22 {
    text-align: center;
}

.Benefits-module--bannerBtn--35e22 .Benefits-module--applyBtn--6fc7c {
    background-color: #fff !important;
    border: 1px solid rgb(13 113 218);
    border-radius: 50rem;
    color: rgb(13 113 218);
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 24px;
    max-width: 266px;
    overflow: hidden;
    padding: 14px 55px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Benefits-module--flipboxback--703ef {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
}

.Vacancies-module--vacancies--01001 {
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    padding: 100px 0;
}

.Vacancies-module--scrollBg--41c99 {
    background: url(https://invozone.com/static/vac-0e1ac4055e40e1260b9cc99ad4ac8478.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 60px;
}

.Vacancies-module--scrollBg--41c99 h2 {
    color: #fff;
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 60px;
    text-align: center;
}

.Vacancies-module--my_custom_scrollbar--38c7d {
    height: 815px;
    padding: 0 80px;
}

@media only screen and (min-width: 769px) {
    .Vacancies-module--table_card_view--9ae1f {
        border-collapse: separate;
        border-spacing: 0 2px;
        position: relative;
    }
}

.Vacancies-module--scrollOverFlow--463bd {
    background: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 25px;
}

@media only screen and (min-width: 769px) {
    .Vacancies-module--scrollOver--a7ff7 {
        overflow-y: scroll;
        padding: 40px;
    }
}

.Vacancies-module--scrollOver--a7ff7 {
    background: #fff;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    height: 785px;
    overflow-y: auto;
    width: 100%;
}

.Vacancies-module--jobCards--d90ae {
    align-items: end;
}

.Vacancies-module--Onsite--f1aff,
.Vacancies-module--full--06e7f {
    border: none;
    border-radius: 50rem;
    color: #000;
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    min-width: 100px;
    overflow: hidden;
    padding: 13px 26px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Vacancies-module--full--06e7f {
    background: #e6eefe;
}

.Vacancies-module--job--af878 {
    cursor: pointer;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    margin-top: 16px;
    max-width: 279px;
}

.Vacancies-module--location--51726 {
    display: flex;
    margin-top: 16px;
}

.Vacancies-module--location--51726 .Vacancies-module--loc--1c522 {
    align-items: center;
    display: flex;
    margin-right: 15px;
}

.Vacancies-module--location--51726 .Vacancies-module--loc--1c522 p {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 8px;
}

.Vacancies-module--overview--c733a {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    max-width: 490px;
}

.Vacancies-module--applyBtn--74b09 {
    background: rgb(13 113 218) !important;
    border: 1px solid rgb(13 113 218) !important;
    border-radius: 50rem;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 44px;
    line-height: 1.5;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    width: 150px;
}

.Engineering-module--number--dce05 {
    background: #fafafa;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    position: relative;
}

.Engineering-module--number--dce05 h2 {
    font-size: 39px;
    font-weight: 700;
    margin: 0 auto;
    max-width: 773px;
    padding-bottom: 24px;
    text-align: center;
}

.Engineering-module--number--dce05 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 776px;
    padding-bottom: 60px;
    text-align: center;
}

.Engineering-module--number--dce05 .Engineering-module--cardsData--7f41c .Engineering-module--cards--08f41 {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNjMiIGhlaWdodD0iMjI2IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMzYzIDIyNiI+PHBhdGggZmlsbD0iI0FCQ0JGRSIgZD0iTTM2Mi41MDMgMjI1LjY3MWgtMTkuNTM2di02LjEwM2gxMy4zNTVWMTYuMTM4aDYuMTIzdjIwOS41MzNoLjA1OFptLTE0Mi4zNTMgMEgwdi0zMS42MTloNi4xMjN2MjUuNDU4aDIxMy45Njh2Ni4xNjFoLjA1OVpNNi4xODIgNzEuNjQ2SDBWLjkxaDI0OC43MjV2Ni4xMDNINi4xMjN2NjQuNjMzaC4wNTlaIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: grid;
    height: 224.761px;
    position: relative;
    right: -45px;
    text-align: center;
    width: 362.504px;
    z-index: 1;
}

.Engineering-module--number--dce05 .Engineering-module--cardsData--7f41c .Engineering-module--cards--08f41 .Engineering-module--imgBg--971cb {
    background: #fff;
    border-radius: 12px;
    bottom: 46px;
    box-shadow: 10px 30px 55px rgba(0, 0, 0, .04);
    height: 96px;
    left: -26px;
    position: absolute;
    width: 95px;
}

.Engineering-module--number--dce05 .Engineering-module--cardsData--7f41c .Engineering-module--cards--08f41 img {
    margin: 15px auto;
}

.Engineering-module--number--dce05 .Engineering-module--cardsData--7f41c .Engineering-module--cards--08f41 .Engineering-module--years--31bc0 {
    margin: 78px auto 0;
}

.Engineering-module--number--dce05 .Engineering-module--cardsData--7f41c .Engineering-module--cards--08f41 span {
    font-size: 39px;
    font-weight: 700;
    margin-top: 20px;
}

.Aspiration-module--SubmitIdea--26a2b {
    margin: 110px 0;
    position: relative;
}

.Aspiration-module--SubmitIdea--26a2b .Aspiration-module--aspireBg--bbc3d {
    background: url(https://invozone.com/static/spireBg-bc897f17545c98a4080719a61fb4a0c2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;
    min-height: 396px;
}


.Aspiration-module--SubmitIdea--26a2b .Aspiration-module--heading--df420 {
    color: #fff;
    padding: 90px 0 0 80px;
}

.Aspiration-module--SubmitIdea--26a2b .Aspiration-module--heading--df420 h2 {
    font-size: 39px;
    font-style: normal;
    font-weight: 400;
    max-width: 582px;
}

.Aspiration-module--SubmitIdea--26a2b .Aspiration-module--heading--df420 h2 span {
    font-weight: 700;
}

.Aspiration-module--aspireBtn--3d931 {
    background-color: white !important;
    border: none;
    border: 1px solid white !important;
    border-radius: 50rem;
    color: #000 !important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 24px;
    max-width: 254px;
    overflow: hidden;
    padding: 15px 35px;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Aspiration-module--SubmitIdea--26a2b .Aspiration-module--bannerImg--62045 {
    max-width: 100%;
    position: absolute;
    right: 76px;
    top: -37px;
}

@media screen and (max-width: 1280px) {
    .Banner-module--futureBtn--587a8 {
        bottom: -38px;
    }

    .Banner-module--futureBtn--587a8 .Banner-module--future--b608f {
        font-size: 24px;
        padding: 15px 48px;
    }

    .Banner-module--section--be768 {
        background-position: 81% 100%;
        padding: 50px 0;
    }

    .WhyWork-module--hireCyber--ec470 .WhyWork-module--hire--ea911 .WhyWork-module--description--90c97 {
        margin-bottom: 0;
    }

    .Benefits-module--benefits--45c05 {
        padding: 60px 0;
    }

    .Benefits-module--flipbox--68471 {
        margin: 0 auto;
    }

    .Vacancies2-module--vacancies--d6e89 {
        padding: 0;
    }

    .Vacancies2-module--con--28476 {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .Vacancies2-module--my_custom_scrollbar--76260 {
        height: 100%;
        padding: 0 15px;
    }

    .Vacancies-module--my_custom_scrollbar--38c7d {
        height: 100%;
        padding: 0 15px;
    }

    .Vacancies-module--scrollBg--41c99 {
        background-repeat: no-repeat;
        background-size: cover;
        background-size: contain;
        color: #fff;
        padding-bottom: 30px;
        padding-top: 60px;
    }

    .Vacancies-module--con--91e73 {
        background: #2176ff;
    }

    .Vacancies-module--scrollOver--a7ff7 {
        padding: 30px 0;
    }

    .Vacancies-module--job--af878 {
        max-width: 100%;
    }

    .Vacancies-module--scrollOverFlow--463bd {
        background-color: #2176ff;
    }

    .Vacancies-module--hr--8410e {
        display: none;
    }

    .Vacancies-module--scrollOver--a7ff7 {
        background-color: #2176ff;
    }

    .Aspiration2-module--SubmitIdea--15b16 {
        padding-left: unset;
    }

    .Aspiration-module--SubmitIdea--26a2b {
        background: #2176ff;
    }

    .Aspiration2-module--SubmitIdea--15b16 .Aspiration2-module--bannerImg--609a1 {
        position: unset;
    }

}

@media screen and (max-width: 767px) {
    .Banner-module--futureBtn--587a8 {
        bottom: -32px;
        display: none;
        position: unset;
    }

    .WhyWork-module--hireCyber--ec470 .WhyWork-module--hire--ea911 .WhyWork-module--description--90c97 {
        margin: 0 auto;
        max-width: 338px;
    }

    .Benefits-module--benefits--45c05 .Benefits-module--dec--dfa26 {
        margin-bottom: 10px;
        max-width: 350px;
    }

    .Vacancies-module--jobCards--d90ae {
        background: #fff;
        border-radius: 24px;
        box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
        margin: 0 0 20px;
        padding: 20px;
    }

    .Vacancies-module--job--af878 {
        max-width: 330px;
    }

    .Vacancies-module--overview--c733a {
        max-width: 330px;
    }

    .Vacancies-module--mailto--11683 {
        color: hsla(0, 0%, 100%, .8);
        font-size: 16px;
        font-weight: 400;
        margin: 0 auto 4px;
        max-width: 223px;
        text-align: center;
    }

    .Vacancies2-module--mail--b1c6b {
        color: #fff !important;
        font-size: 20px;
        font-weight: 600;
    }

    .Aspiration-module--aspireBtn--3d931{
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 768px) {
    .WhyWork-module--hireCyber--ec470 {
        padding: 60px 0 30px;
    }
}

@media screen and (max-width: 992px) {

    .Aspiration-module--aspireBg--bbc3d {
        margin: 0 auto;
        padding: 60px 0;
        text-align: center;
    }

    .Aspiration2-module--SubmitIdea--15b16 .Aspiration2-module--heading--807a8 h2 {
        margin: 0 auto;
        text-align: center;
    }

    .Aspiration-module--SubmitIdea--26a2b .Aspiration-module--heading--df420{
        padding: 0;
    }
}