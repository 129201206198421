.Banner-module--banner--66017 {
    background-image: url('/src/images/bannerBg-35.svg');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 80px 0 0;
    position: relative;
    width: 100%;
}

.Banner-module--banner--66017 .Banner-module--heading--85745 {
    margin-top: 40px;
}

.Banner-module--banner--66017 .Banner-module--heading--85745 .Banner-module--bannerHeading--2cd13 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 616px;
}

.Banner-module--banner--66017 .Banner-module--heading--85745 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    max-width: 568px;
    padding-top: 5px;
}

.Banner-module--bannerBtn--ea3f6 {
    padding-top: 36px;
}

.Banner-module--btn_white2_border--17068 {
    background-color: white !important;
    border: none;
    border-radius: 50rem;
    color: #000 !important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Explore-module--subservice--312e6 {
    padding: 100px 0;
    position: relative;
}

.Explore-module--subservice--312e6 .Explore-module--heading--084a5 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    text-align: center;
}

.Explore-module--subservice--312e6 .Explore-module--description--ecb11 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.Explore-module--cardBg--47d64 {
    background: #f7f7f7;
    border-radius: 24px;
    bottom: 71px;
    left: 0;
    margin: 0 auto;
    max-width: 968px;
    min-height: 710px;
    position: absolute;
    right: 0;
}

.Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb {
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

.Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    padding: 36px 31px 23px 34px;
}

.Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 img {
    margin-bottom: 18px;
    max-width: 60px;
}

.Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 h3 {
    cursor: default;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 16px;
}

.Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
    min-height: 130px;
    text-align: left;
}

.Boost-module--premiumBg--9b782 {
    height: 870px;
}

.Boost-module--teamWork--e3ef2 {
    background: #fafafa;
    height: 470px;
}

.Boost-module--heading--7039e {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 669px;
    padding-top: 88px;
    text-align: center;
}

.Boost-module--description--a4aa4 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 890px;
    padding-bottom: 60px;
    padding-top: 25px;
    position: relative;
    text-align: left;
    text-align: center;
    z-index: 1;
}

.Boost-module--SliderWidth--68ca7 {
    width: 100%;
}

.eCommercce {
    background: #fff;
    border-radius: 16px;
    bottom: -60px;
    display: block;
    height: 32px;
    left: 0;
    margin: 28px auto -6px;
    max-width: 500px;
    max-width: 155px;
    padding: 10px;
    position: absolute;
    right: 0;
    text-align: center;
    z-index: 10;
}

.eCommercce li.slick-active {
    background: rgba(56, 127, 255, .27);
    border-radius: 55px;
    position: relative;
}

.eCommercce li {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0 3px;
}

.eCommercce li.slick-active button {
    background-color: #387fff;
    position: relative;
}

.eCommercce li button {
    background: #e0e0e0;
    border: none;
    border-radius: 44px;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    padding: 0;
    width: 10px;
}

.eCommercce li.slick-active button:after {
    border-radius: 55px;
    content: " ";
    min-height: 14px;
    min-width: 14px;
    padding: 7px;
    position: absolute;
    right: -3px;
    top: -3px;
}

.eCommercce li {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0 3px;
}

.eCommercce li button {
    background: #e0e0e0;
    border: none;
    border-radius: 44px;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 10px;
    padding: 0;
    width: 10px;
}

.sliderECom .slick-slide {
    height: inherit !important;
}

.slick-initialized .slick-slide {
    display: block;
}

.Boost-module--cardWrapper--e0cae .Boost-module--card--94596 {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    max-width: 100%;
    min-height: 310px;
    padding: 20px;
}

.Boost-module--cardWrapper--e0cae .Boost-module--card--94596 h3 {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 8px;
    padding-top: 12px;
}

.Boost-module--cardWrapper--e0cae .Boost-module--card--94596 p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    line-height: 28px;
    margin: 0;
    text-align: left;
    width: 335px;
}

.Industry-module--ready--a00f8 {
    color: #fff;
}

.Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 {
    background: url('/src/images/business-models-banner-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    max-height: 431px;
    padding: 71px 80px;
}

.Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 h2 {
    font-size: 39px;
    font-weight: 400;
    line-height: 52px;
}

.Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 h2 {
    font-size: 39px;
    font-weight: 400;
    line-height: 52px;
}

.Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 .Industry-module--btn_white2_border--83102 {
    background-color: white !important;
    border: none;
    border-radius: 50rem;
    color: #000 !important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 24px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Industry-module--ready--a00f8 .Industry-module--bnrImg--b3196 {
    bottom: 150px;
    height: 510px;
    /* position: absolute; */
    width: 494px;
}

.Disposal-module--Ideation--9e497 {
    padding: 100px 0;
    position: relative;
}

.Disposal-module--Ideation--9e497 .Disposal-module--heading--0b2eb {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 750px;
    padding-bottom: 24px;
    text-align: center;
}

.Disposal-module--Ideation--9e497 .Disposal-module--dec--a27d9 {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto 25px;
    max-width: 760px;
    text-align: center;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 {
    align-items: center;
    display: flex;
    position: relative;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--leftText--a320d {
    left: -25px;
    position: absolute;
    top: 18px;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards--0a8d8 {
    max-width: 328px;
    padding: 17px 15px 25px 21px;
    position: relative;
    text-align: right;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--peakNumber2--11ccc {
    align-items: center;
    display: flex;
    justify-content: end;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--peakNumber2--11ccc .Disposal-module--serial2--6747f {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.Disposal-module--research--7dc62 {
    align-items: baseline;
    display: flex;
    margin-bottom: -6px;
}

.Disposal-module--research--7dc62 span {
    color: #000 !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 30px;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards--0a8d8 h3 {
    font-size: 20px;
    font-weight: 700;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards--0a8d8 p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
    padding-top: 10px;
}

.Disposal-module--Ideation--9e497 .Disposal-module--closeUp--32d9c {
    height: 580px;
    margin: 0 auto;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--rightText--b7b0d {
    position: absolute;
    right: -25px;
    top: 18px;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards2--ce80d {
    max-width: 328px;
    padding: 17px 15px 25px 21px;
    position: relative;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--peakNumber--d5c16 {
    align-items: center;
    display: flex;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--peakNumber--d5c16 .Disposal-module--serial2--6747f {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards2--ce80d h3 {
    font-size: 20px;
    font-weight: 700;
}

.Disposal-module--Ideation--9e497 .Disposal-module--cardsData--06386 .Disposal-module--cards2--ce80d p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
    padding-top: 10px;
}

.Disposal-module--bannerBtn--21a75 {
    text-align: center;
}

.Benefit-module--hireCyber--1ab56 {
    background: #fafafa;
    padding: 100px 0;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--hire--037db {
    text-align: center;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--hire--037db h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 24px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--hire--037db .Benefit-module--description--24589 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 862px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--hireImg2--85be8 {
    padding-bottom: 35px;
    padding-top: 48px;
    text-align: center;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--cards--60a8f {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 11px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--cards--60a8f .Benefit-module--talent--d8e87 {
    align-items: flex-start;
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    display: flex;
    margin: 10px;
    padding: 15px;
    width: 420px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--cards--60a8f .Benefit-module--talent--d8e87 .Benefit-module--tick--c22a4 {
    margin-top: 3px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--cards--60a8f .Benefit-module--talent--d8e87 .Benefit-module--text--0810a {
    margin-left: 12px;
}

.Benefit-module--hireCyber--1ab56 .Benefit-module--cards--60a8f .Benefit-module--talent--d8e87 p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
}

.Outsource-module--gotProject--0f72c {
    background-image: url('/src/images/got-project.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 758px;
    padding: 100px 0;
}

.Outsource-module--gotProject--0f72c .Outsource-module--form--9800a {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    max-width: 1281px;
    min-height: 558px;
}

.Outsource-module--projectForm--c664e {
    display: flex;
}

.Outsource-module--gotProject--0f72c .Outsource-module--form--9800a .Outsource-module--formData--6a2dd {
    padding: 53px 64px;
}

.Outsource-module--gotProject--0f72c .Outsource-module--form--9800a .Outsource-module--formData--6a2dd h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    max-width: 681px;
}

.Outsource-module--briefSec--d9b24 {
    background: rgba(236, 244, 255, .5);
    border-radius: 0 12px 12px 0;
    max-width: 472px;
    padding: 53px 53px 8px 64px;
    position: relative;
}

.Outsource-module--briefSec--d9b24 .Outsource-module--brief--b61ae h3 {
    font-size: 20px;
    font-weight: 700;
}

.Outsource-module--containerIntro--d5c8d {
    margin-bottom: 22px;
    margin-top: 30px;
    padding-left: 24px;
}

.Outsource-module--briefDot--55603 {
    background-color: #7388a5;
    border-radius: 50%;
    color: #7388a5;
    height: 5px;
    left: 64px;
    margin-top: 15px;
    position: absolute;
    width: 5px;
}

.Outsource-module--briefSec--d9b24 .Outsource-module--brief--b61ae h4 {
    color: #212529;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding-top: 20px;
}

.Outsource-module--containerIntro--d5c8d h4,
.Outsource-module--containerIntro--d5c8d p {
    color: #7388a5;
    display: inline;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    max-width: 337px;
    padding-top: 20px;
}

.tech {
    background: #fafafa;
    background-image: url(https://invozone.com/static/image192-59bf3471f7c6415bd27a09c9fce912e2.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.Tools2-module--globalIndus--eaf65 {
    padding: 100px 0;
    position: relative;
}

.Tools2-module--heading--bbf9a {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    margin: 0 auto;
    padding-bottom: 24px;
    text-align: center;
}

.Tools2-module--description--c263a {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 685px;
    text-align: center;
}

.Tools2-module--cardBg--4ef53 {
    background: #fff;
    border-radius: 24px;
    bottom: 105px;
    left: 0;
    margin: 0 auto;
    max-width: 1280px;
    min-height: 464px;
    position: absolute;
    right: 0;
}

.Tools2-module--cards--28f17 {
    position: relative;
}

.Tools2-module--cards--28f17 .Tools2-module--cardsContent--90593 {
    border-radius: 24px;
}

.commerceTab {
    background-color: unset;
    flex-wrap: wrap !important;
    justify-content: center;
    min-height: unset;
    padding: 0 100px;
}

.commerceTab.nav-pills .nav-link {
    background: #fff;
    border-radius: 27px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    color: #212529;
    display: flex;
    flex-wrap: wrap;
    font-size: 22px;
    font-weight: 700;
    height: 48px;
    letter-spacing: unset;
    line-height: 32px;
    margin: 15px 8px;
    padding-left: 0 !important;
    padding: unset !important;
    position: relative;
    text-align: unset;
}

.commerceTab.nav-pills .nav-link.active {
    background: rgb(30 19 133);
    color: #fff;
}

.Tools2-module--tabData--aeee7 {
    align-items: center;
    display: flex;
    padding: 8px 30px;
    cursor: pointer;
}

.Tools2-module--tabData--aeee7 p {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.Blocks,
.contentBlock {
    position: relative;
}

.contentBlock {
    align-items: center;
    background: unset;
    border-radius: 12px;
    box-shadow: unset;
    display: unset;
    min-height: 100%;
    min-height: 423px;
    padding-left: 38px;
    padding-right: 20px;
    z-index: 9;
}

.Tools2-module--techIcon--575c9 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Tools2-module--iconssCard--e9737 {
    background: #fff;
    border-radius: 8px;
    margin: 15px;
    z-index: 1;
}

.Tools2-module--technologyIcon--89419 {
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    height: 161px;
    padding: 12px;
    text-align: center;
    width: 216px;
}

.Tools2-module--technologyIcon--89419 .Tools2-module--techImg--de605 {
    align-items: center;
    border-radius: 4px;
    display: flex;
    height: 88px;
    justify-content: center;
    text-align: center;
}

.Tools2-module--technologyIcon--89419 h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding-top: 10px;
    text-align: center !important;
}

.Hire-module--banner--2cbea {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #000;
    padding: 110px 0;
    position: relative;
    width: 100%;
}

.Hire-module--banner--2cbea .Hire-module--heading--8a8ca .Hire-module--bannerHeading--beba2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 616px;
}

.Hire-module--banner--2cbea .Hire-module--heading--8a8ca p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
    max-width: 568px;
    padding-top: 5px;
}

.Hire-module--bannerBtn--f999b {
    padding-top: 36px;
}

.Hire-module--btn_white2_border--1d752 {
    background-color: #2176ff !important;
    border: none;
    border-radius: 50rem;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.paddingEngagement {
    padding-top: 100px;
}

.Services-module--subservice--319fd {
    background-color: #1f75ff;
    background-image: url(https://invozone.com/static/cost-estimation-section-bg-pattern-abe0a991c0e141ce0c0bfb64c9a6bb1e.svg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 100px 0;
    position: relative;
}

.Services-module--subservice--319fd .Services-module--heading--9c742 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    text-align: center;
}

.Services-module--subservice--319fd .Services-module--description--68845 {
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.Services-module--subservice--319fd .Services-module--cardWrapper--fdf84 {
    margin-top: 34px;
    position: relative;
    z-index: 99;
}

.Services-module--subservice--319fd .Services-module--cardWrapper--fdf84 .Services-module--card--e126e {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    padding: 36px 31px 38px 34px;
    text-align: center;
}

.Services-module--subservice--319fd .Services-module--cardWrapper--fdf84 .Services-module--card--e126e img {
    margin-bottom: 18px;
    max-width: 60px;
}

.Services-module--subservice--319fd .Services-module--cardWrapper--fdf84 .Services-module--card--e126e h3 {
    color: #000;
    cursor: default;
    font-family: inherit;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 10px;
}

.Services-module--subservice--319fd .Services-module--cardWrapper--fdf84 .Services-module--card--e126e p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
}

.Ready-module--subservice--6ac92 {
    color: #fff;
    padding: 100px 0;
    position: relative;
}

.Ready-module--subservice--6ac92 .Ready-module--Ready--f0594 {
    background: url(https://invozone.com/static/get-connected-today-banner-bg-9788820e2afa73a13fdc5288076d80a6.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 382px;
    padding: 100px 0;
}

.Ready-module--subservice--6ac92 .Ready-module--description2--84d4f {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding-bottom: 24px;
    text-align: left;
    text-align: center;
}

.Ready-module--subservice--6ac92 .Ready-module--heading--22a86 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    text-align: center;
}

.Ready-module--subservice--6ac92 .Ready-module--description--d28ee {
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 769px;
    padding: 24px 0 36px;
    text-align: left;
    text-align: center;
}

@media (max-width: 1280px) {
    .Disposal-module--Ideation--9e497 {
        display: none;
    }

    .Banner-module--banner--66017 {
        padding: 60px 0 0;
    }

    .Banner-module--banner--66017 .Banner-module--heading--85745 {
        margin-top: 0;
        text-align: center;
    }

    .Banner-module--banner--66017 .Banner-module--heading--85745 p {
        margin: 0 auto;
        max-width: 556px;
    }

    .Banner-module--bannerBtn--ea3f6 {
        text-align: center;
    }

    .Banner-module--bnrImg--370cf {
        text-align: center;
    }

    .Banner-module--BannerImage--c9643 {
        left: 0;
        margin: 66px auto 0;
        position: absolute;
        position: unset;
        right: 0;
        top: -30px;
    }

    .Explore-module--subservice--312e6 {
        padding: 60px 0;
    }

    .Explore-module--subservice--312e6 .Explore-module--heading--084a5 {
        margin-bottom: 10px;
    }

    .Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb {
        margin-top: 50px;
    }

    .Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 {
        box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
        max-width: unset;
        min-height: 340px;
        outline: none;
        padding: 24px 24px 17px;
    }

    .Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 {
        background: #2176ff;
        max-height: unset;
        padding: 71px 80px 0;
    }

    .Industry-module--ready--a00f8 .Industry-module--bnrImg--b3196 {
        margin-top: 60px;
        padding-top: 40px;
        position: unset;
    }

    .Outsource-module--gotProject--0f72c {
        padding: 60px 0;
    }

    .Outsource-module--projectForm--c664e {
        display: unset;
        flex-wrap: wrap;
    }

    .Outsource-module--gotProject--0f72c .Outsource-module--form--9800a .Outsource-module--formData--6a2dd {
        padding: 24px;
    }

    .Outsource-module--gotProject--0f72c .Outsource-module--form--9800a .Outsource-module--formData--6a2dd h2 {
        text-align: left;
    }

    .EngagementModels-module--customized--98ee7 .EngagementModels-module--engagment--08989 {
        padding: 60px 0;
    }

    .paddingEngagement {
        padding-bottom: unset !important;
        padding-top: unset;
    }

    .EngagementModels-module--customized--98ee7 {
        padding: 60px 0;
    }

    .EngagementModels-module--subHeading--ed660 {
        padding-bottom: 60px;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 {
        bottom: -94px;
        justify-content: center;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea {
        min-height: 190px;
        width: 203px;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea .EngagementModels-module--pHead--b6403 {
        margin: 0 auto;
        max-width: 100px;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea {
        min-height: 190px;
        width: 203px;
    }
    .Ready-module--subservice--6ac92 {
        padding: 0;
    }
    .Ready-module--subservice--6ac92 .Ready-module--con--3c35a {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .Ready-module--subservice--6ac92 .Ready-module--Ready--f0594 {
        background: url('https://invozone.com/static/ready-for-ecommerce-service-423f555f2b017908334d3256438be0c4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
    }
    .Ready-module--subservice--6ac92 .Ready-module--description2--84d4f {
        max-width: 550px;
    }
    .Ready-module--subservice--6ac92 .Ready-module--description--d28ee {
        max-width: 450px;
    }
}

@media (max-width: 600px) {
    .EngagementModels-module--subHeading--ed660 {
        line-height: 155%;
        max-width: 350px;
        padding-bottom: 30px!important;
    }
    .EngagementModels-module--mainHeading--6143f {
        font-size: 36px;
        font-weight: 700;
        line-height: 52px;
        max-width: 350px;
    }

    .Banner-module--banner--66017 .Banner-module--heading--85745 {
        text-align: center;
    }

    .Banner-module--banner--66017 .Banner-module--heading--85745 .Banner-module--bannerHeading--2cd13 {
        font-size: 48px;
        line-height: 68px;
        max-width: 352px;
    }

    .Banner-module--bannerBtn--ea3f6 {
        text-align: center;
    }

    .Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 .Industry-module--bannerBtn--b5e2b {
        text-align: center;
    }

    .Outsource-module--gotProject--0f72c {
        background-image: url('https://invozone.com/static/gotBg-5aba1867dcccdb9eae3f3c196772ec98.svg');
        padding-bottom: 60px;
        padding-top: 60px;
    }
}

@media (max-width: 1200px) {
    .Banner-module--banner--66017 .Banner-module--heading--85745 .Banner-module--bannerHeading--2cd13 {
        max-width: 659px;
        min-width: 0;
    }
}

@media (max-width: 767px) {
    .Ready-module--subservice--6ac92 .Ready-module--heading--22a86 {
        max-width: 350px;
    }
    .Ready-module--subservice--6ac92 .Ready-module--description--d28ee {
        max-width: 350px;
        padding: 14px 0 36px;
    }
    .Ready-module--subservice--6ac92 .Ready-module--description2--84d4f {
        max-width: 318px;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea {
        align-items: center;
        display: flex;
        max-width: 350px;
        min-height: 86px;
        width: 100%;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea .EngagementModels-module--pHead--b6403 {
        max-width: unset;
        padding-left: 20px;
        padding-top: unset;
        text-align: left;
        width: 100%;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 .EngagementModels-module--card--49dea {
        align-items: center;
        display: flex;
        max-width: 350px;
        min-height: 86px;
        width: 100%;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--flexWrapper--fd313 {
        flex-direction: column;
        position: unset;
    }
    .EngagementModels-module--customized--98ee7 .EngagementModels-module--con--c6774 {
        padding-left: 0;
        padding-right: 0;
    }

    .EngagementModels-module--customized--98ee7 {
        padding: unset;
    }

    .EngagementModels-module--customized--98ee7 .EngagementModels-module--engagment--08989 {
        background-color: #2176ff;
        border-radius: 0;
    }

    .Industry-module--ready--a00f8 .Industry-module--bnrImg--b3196 {
        display: none;
    }

    .Banner-module--BannerImage--c9643 {
        max-width: 350px;
        top: 0;
    }

    .Explore-module--subservice--312e6 .Explore-module--heading--084a5 {
        max-width: 350px;
    }

    .Explore-module--subservice--312e6 .Explore-module--description--ecb11 {
        max-width: 350px;
    }

    .Explore-module--cardBg--47d64 {
        bottom: 29px;
        max-width: 284px;
        min-height: 1988px;
    }

    .Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb {
        margin-top: 60px;
    }

    .Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 {
        max-width: unset;
    }

    .Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 {
        border-radius: unset;
    }

    .Industry-module--ready--a00f8 .Industry-module--readyBg--7ef89 {
        background: url('https://invozone.com/static/market-tested-ecommerce-servicce-8974085adbf8c4292a97f9e9e4d093d9.svg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 1057px;
        max-height: unset;
        padding: 71px 21px;
    }

}

@media screen and (max-width: 992px) {
    .Explore-module--subservice--312e6 .Explore-module--cardWrapper--7d9bb .Explore-module--card--7f9b6 p {
        max-width: 100%;
    }

    .EngagementModels-module--subHeading--ed660 {
        padding-bottom: 50px !important;
        text-align: center;
    }
}

@media (max-width: 1024px) {
    .Industry-module--ready--a00f8 {
        text-align: center;
    }
}