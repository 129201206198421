.ContactSales-module--section--afe0d {
    background-color: #f8f8f8;
    background-position: 100%;
    background-repeat: no-repeat;
    position: relative;
}

.ContactSales-module--personCard--00251 {
    align-items: flex-start;
    display: flex;
    padding-top: 1.2rem;
}

.ContactSales-module--contactIcons--31b38 {
    font-size: 1.5rem;
}

.ContactSales-module--contactIcons--31b38 a:not(:last-child) {
    margin-right: 1rem;
}

.ContactSales-module--contactIcons--31b38 a {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.ContactSales-module--section--afe0d a {
    color: inherit;
    font-weight: 700;
}

.ContactSales-module--contactIcons--31b38 img {
    width: 25px;
}

.ContactSales-module--card--2d5a9 {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 16px 16px 84px 0 rgba(0, 0, 0, .04);
    color: #fff;
    max-width: 481px;
    padding: 35px 25px;
}

.ContactSalesForm-module--fieldColor--2d9c9 input {
    background: #f8f8f8 !important;
    border: 0;
}

.form-control {
    border: none;
    font-size: 14px;
    font-weight: 400;
}

.form-control {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.75rem;
    color: #495057;
    display: block;
    font-size: 1rem;
    height: calc(1.5em + 1rem + 2px);
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: 80%;
    margin-top: 0.25rem;
    width: 100%;
}

.form-group {
    margin-bottom: 1rem;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.react-tel-input {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    position: relative;
    width: 100%;
}

.react-tel-input .special-label {
    display: none;
    white-space: nowrap;
}

.react-tel-input .special-label {
    background: #fff;
    font-size: 13px;
    left: 46px;
    padding: 0 2px;
    position: absolute;
    top: -8px;
    z-index: 1;
}

.phoneSelect .form-control {
    background-clip: padding-box !important;
    background-color: #fff !important;
    border: 1px solid #e7e7e7;
    border-radius: 0.75rem !important;
    color: #495057 !important;
    display: block !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: calc(1.5em + 1rem + 2px) !important;
    line-height: 1.5 !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100% !important;
}

.react-tel-input .form-control {
    background: #fff;
    border: 1px solid #cacaca !important;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    letter-spacing: .01rem;
    line-height: 25px;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-top: 0 !important;
    outline: none;
    padding-left: 48px;
    position: relative;
    width: 300px;
}

.react-tel-input .flag-dropdown {
    background-color: unset !important;
    border: 1px solid #e7e7e7;
    border-bottom-left-radius: 0.75rem !important;
    border-right-color: transparent !important;
    border-top-left-radius: 0.75rem !important;
    box-shadow: none;
    outline: none !important;
}

.react-tel-input .flag-dropdown {
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    bottom: 0;
    padding: 0;
    position: absolute;
    top: 0;
}

.react-tel-input .selected-flag {
    border-radius: 3px 0 0 3px;
    height: 100%;
    outline: none;
    padding: 0 0 0 8px;
    position: relative;
    width: 38px;
}

[role=button] {
    cursor: pointer;
}

.react-tel-input .selected-flag .flag {
    margin-top: -5px;
    position: absolute;
    top: 50%;
}

.react-tel-input .selected-flag .arrow {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgYXJpYS1oaWRkZW49InRydWUiIGNsYXNzPSJwcmVmaXhfX2Nzcy10ajViZGUtU3ZnIiB2aWV3Qm94PSIwIDAgMjAgMjAiPjxwYXRoIGQ9Ik00LjUxNiA3LjU0OGMuNDM2LS40NDYgMS4wNDMtLjQ4MSAxLjU3NiAwTDEwIDExLjI5NWwzLjkwOC0zLjc0N2MuNTMzLS40ODEgMS4xNDEtLjQ0NiAxLjU3NCAwIC40MzYuNDQ1LjQwOCAxLjE5NyAwIDEuNjE1LS40MDYuNDE4LTQuNjk1IDQuNTAyLTQuNjk1IDQuNTAyYTEuMDk1IDEuMDk1IDAgMCAxLTEuNTc2IDBTNC45MjQgOS41ODEgNC41MTYgOS4xNjNzLS40MzYtMS4xNyAwLTEuNjE1eiIvPjwvc3ZnPg==);
    background-size: 100% 100% !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    height: 100% !important;
    left: 27px !important;
    margin-top: 0 !important;
    position: relative;
    transform: translate(-50%, -50%);
    width: 100% !important;
}

.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
}

.css-7pg0cj-a11yText {
    z-index: 9999;
    border: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    white-space: nowrap;
}

.css-a33x3s-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(248, 248, 248);
    border-radius: 0.75rem;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 43px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    border: 0px;
    outline: 0px !important;
}

.css-1hwfws3 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.css-1wa3eu0-placeholder {
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}

.css-1g6gooi {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-xzu46z-indicatorContainer {
    color: rgb(0, 0, 0);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}

.css-8mmkcg {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}

.ContactSalesForm-module--fieldColor--2d9c9 textarea {
    background: #f8f8f8 !important;
    border: 0;
}

textarea.form-control {
    height: auto;
}

.ContactSalesForm-module--salesFormRowHeight--f968e {
    color: #333;
}

.ContactSalesForm-module--salesFormCheckBoxSize--dfe0a {
    color: #333;
}

.form-check {
    display: block;
    padding-left: 1.25rem;
    position: relative;
}

.ContactSalesForm-module--salesFormCheckBoxSize--dfe0a input {
    height: 20px;
    width: 20px;
}

.form-check-label {
    margin-bottom: 0;
}

.ContactSalesForm-module--attachment--e028e {
    color: #333;
}

.ContactSalesForm-module--attachmentLabel--02256 {
    font-size: 12px;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.invisible {
    visibility: hidden !important;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.btn_white_border {
    background-color: rgb(30 19 133) !important;
    border: 1px solid rgb(30 19 133) !important;
    border-radius: 50rem;
    color: white !important;
    display: inline-block;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    min-width: 180px;
    overflow: hidden;
    padding: 0.5rem 2rem !important;
    position: relative;
    text-align: center !important;
    transition: all .3s !important;
    vertical-align: middle !important;
    z-index: 1;
}

.btn_white_border1 {
    background-color: wheat !important;
    border: 1px solid white !important;
    border-radius: 50rem;
    color: black !important;
    display: inline-block;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    min-width: 180px;
    overflow: hidden;
    padding: 0.5rem 2rem !important;
    position: relative;
    text-align: center !important;
    transition: all .3s !important;
    vertical-align: middle !important;
    z-index: 1;
}

.ContactSalesForm-module--salesFormCheckBoxSize--dfe0a span {
    font-size: 12px;
}

a {
    background-color: transparent;
    color: #2176ff;
    text-decoration: none;
}

.form-check-input {
    margin-top: 0.3rem !important;
    position: absolute !important;
}

.saleimage {
    height: 160px;
}


@media (min-width: 768px) {
    .ContactSalesForm-module--attachment--e028e {
        height: 25px;
    }
}

@media (min-width: 768px) {
    .ContactSalesForm-module--salesFormRowHeight--f968e {
        min-height: 20px;
    }
}

.react-tel-input .country-list {
    border-radius: 0.75rem !important;
}

.react-tel-input .country-list .country-name {
    color: black;
}

.react-tel-input .selected-flag .arrow.up {
    border-bottom: none !important;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.form-selec {
    background-color: rgb(248, 248, 248) !important;
    border-radius: 0.75rem !important;
    font-size: 16px !important;
}

.form-select {
    border-radius: 0.75rem !important;
    font-size: 16px !important;
    border-color: inherit !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    background: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    background-color: inherit !important;
}

@media (max-width: 768px) {
    .ContactSalesForm-module--salesFormRowHeight--f968e {
        min-height: 30px;
    }

    .ContactSalesForm-module--attachment--e028e {
        display: none;
        height: 30px;
    }

    .ContactSalesForm-module--salesFormRowHeight--f968e {
        min-height: 30px;
    }
    .ContactSalesForm-module--salesFormRowHeight--f968e {
        min-height: 30px;
    }
}