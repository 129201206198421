.VideoSection-module--section--ff046 {
    background: #f8f8f8;
}

.VideoSection-module--videoBanner--cc493 {
    height: 0;
    padding-top: 56.25%;
    position: relative;
    width: 100%;
}

.VideoSection-module--imgContainer--f92e6 {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
}

.VideoSection-module--videoBanner--cc493 .VideoSection-module--play_icon--c57e1 {
    cursor: pointer;
    position: absolute;
}