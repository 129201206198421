.Engagement-module--illustrationContainer--08133 {
    display: flex;
    justify-content: center;
    max-width: 374px;
}

.Engagement-module--engagementCards--2d292 {
    align-items: center;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 16px 16px 84px 0 rgba(0,0,0,.04);
    display: flex;
    justify-content: center;
    margin: 15px 0;
    min-height: 120px;
    width: 90%;
}

@media (max-width: 768px){
.Engagement-module--engagementCards--2d292 {
    width: 100%;
}
}

.pl-3, .px-3 {
    padding-left: 1rem!important;
}

.btn_black_border, .btn_black_border_banner {
    border: 1px solid var(--primary)!important;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 20px;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
}

.btn_black_border {
    background: transparent;
    background: #2176ff!important;
    font-size: 16px;
    font-weight: 700;
}

/* a {
    background-color: transparent !important;
    color: #2176ff !important;
    text-decoration: none !important;
} */

@media (min-width: 768px){
.ml-md-5, .mx-md-5 {
    margin-left: 3rem!important;
}
}