section {
    padding: 96px 0;
}

@media (max-width: 765px) {
    section {
        padding: 60px 0;
    }
}

.main-heading-36 {
    font-size: 36px;
}

.Industries-module--section--dd8b5 {
    background: #f8f8f8;
}

.Industries-module--sliderContainer--8cd67 {
    padding: 100px 70px 0;
}

.slick-slider {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-user-select: none;
    user-select: none;
}

.slick-list,
.slick-slider {
    display: block;
    position: relative;
}

.Industries-module--iconContainerLeft--fc930 {
    right: 100%;
    position: absolute;
    top: 50%;
    z-index: 1;
}

.Industries-module--portfolioArrowIconCover--6836a {
    align-items: center;
    background-color: rgb(30 19 133);
    border-radius: 30px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    color: #fefefe;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
    z-index: 1;
}

.Industries-module--portfolioArrowIconCover--6836a img {
    filter: brightness(0) invert(1);
}

.Industries-module--portfolioArrowIcon--0960b {
    width: 25px;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translateZ(0);
}

.slick-list {
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.slick-list,
.slick-slider {
    display: block;
    position: relative;
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;
}

.slick-track {
    margin: auto;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.Industries-module--iconContainer--c85af {
    display: inline;
    position: absolute;
    left: 100%;
    top: 50%;
}

.Industries-module--industryCard--64707 {
    background-position: 50%;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    height: 350px;
    justify-content: center;
    margin-top: 30px;
    opacity: .6;
    position: relative;
    width: 300px;
}

.gatsby-image-wrapper-constrained {
    display: inline-block;
    vertical-align: top;
}

.gatsby-image-wrapper {
    position: relative;
    overflow: hidden;
}

.gatsby-image-wrapper img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
}

.Industries-module--industryCard--64707 img {
    border-radius: 7px;
}

.gatsby-image-wrapper [data-main-image] {
    opacity: 0;
    transform: translateZ(0);
    transition: opacity .25s linear;
    will-change: opacity;
}

.Industries-module--industryCard--64707 .Industries-module--para--ccdce {
    position: absolute;
    top: 8px;
}

.Industries-module--para--ccdce {
    font-size: 1.75rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

.text-light {
    color: #f8f9fa !important;
    font-weight: 600;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.Industries-module--industryCard--64707 button {
    display: none;
    width: auto;
}


.slick-center div div div {
    background-position: 50%;
    background-size: cover;
    border-radius: 7px;
    display: flex;
    height: 350px;
    justify-content: center;
    margin-top: 15px;
    opacity: 1;
    position: relative;
}

.Industries-module--portfolioArrowRightIconCover--edfcd {
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 30px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    color: #000;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
}

.home_button__bar {
    border-bottom: 1px solid #000;
    /* display: flex; */
    justify-content: center;
    margin: auto auto auto -70px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 15px;
    width: 100%;
}

.home_button__bar li {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin: 0 11px;
    padding-bottom: 10px;
}

.home_button__bar li.slick-active h3 {
    color: #2176ff;
    font-weight: 700 !important;
}

.home_button__bar li h3 {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    margin-bottom: 0;
}


.center-element {
    opacity: 1;
    /* height: 100%; */
    transition: opacity 0.5s;
}

@media (max-width: 480px) {
    .Industries-module--iconContainerLeft--fc930 {
        right: 90%;
    }

    .Industries-module--iconContainer--c85af {
        left: 90%;
    }

    .WorkPortfolio-module--iconContainerLeft--4d499 {
        right: 13%;
    }
}

@media (max-width: 1024px){
    .Industries-module--iconContainerLeft--fc930 {
        right: 75%;
    }
    .Industries-module--iconContainer--c85af{
        left: 75%;
    }
}

@media (max-width: 767px){
    .Industries-module--iconContainerLeft--fc930 {
        right: 90%;
    }
    .Industries-module--iconContainer--c85af{
        left: 90%;
    }
}