.Banner-module--section--9bbff {
    background-color: #f2f2f2;
    background-image: url(https://invozone.com/static/banner-bg-9b62edead1cdba5ada17d1ae7ea2a765.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 196px;
    padding-top: 124px;
}

.SubServices-module--section--f9a5e {
    background-color: #f2f2f2;
    padding-bottom: 50px;
}

.GitStrip-module--section--188b0 {
    background-image: url(https://invozone.com/static/git-strip-bg-cc23e7a4d87c1ff5ff2b61609cfd58f0.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 48px;
    padding-top: 48px;
}

@media (min-width: 768px){
.pl-md-5, .px-md-5 {
    padding-left: 3rem!important;
}
}

