.Banner-module--banner--f8dbb {
    background-image: url('/src/images/aide.webp');
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding-bottom: 125px;
    padding-top: 125px;
    position: relative;
    width: 100%;
}

.Banner-module--btn_white1_border--dbd88, .Banner-module--btn_white2_border--40925 {
    border: none;
    border-radius: 50rem;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}
.Banner-module--btn_white1_border--dbd88 {
    background: #2176ff;
    color: #fff!important;
    margin-bottom: 24px;
    min-width: 100px;
}

.Banner-module--banner--f8dbb .Banner-module--heading--43ee2 .Banner-module--bannerHeading--51a51 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 684px;
}
.Banner-module--banner--f8dbb .Banner-module--heading--43ee2 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 155%;
    margin: 0;
    max-width: 587px;
    padding-bottom: 8px;
    padding-top: 15px;
}

.Banner-module--banner--f8dbb .Banner-module--heading--43ee2 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 155%;
    margin: 0;
    max-width: 587px;
    padding-bottom: 8px;
    padding-top: 15px;
}

.Banner-module--bannerBtn--3a500 {
    padding-top: 36px;
}

.Banner-module--bannerBtn--3a500 a {
    color: #2476fc;
}
.Banner-module--btn_white2_border--40925 {
    background-color: #fff!important;
    background: #2176ff;
    max-width: 214px;
}

@media (min-width: 992px){
.ArtificialIntelligence-module--subservice--bedd2 {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 98px;
    padding-top: 98px;
}
}

.ArtificialIntelligence-module--cir--9b221 {
    position: relative;
}

.ArtificialIntelligence-module--cir--9b221 img {
    position: absolute;
    right: 0;
    top: 100px;
}

.ArtificialIntelligence-module--dots1--6328d {
    position: relative;
}

.ArtificialIntelligence-module--dots1--6328d img {
    left: 12rem;
    position: absolute;
    top: 19rem;
}

.ArtificialIntelligence-module--Group--0911a {
    position: relative;
}

.ArtificialIntelligence-module--Group--0911a img {
    position: absolute;
}

@media screen and (min-width: 769px){
.ArtificialIntelligence-module--react--d581b {
    display: none;
}
}

.ArtificialIntelligence-module--react--d581b {
    position: relative;
}

.ArtificialIntelligence-module--react--d581b img {
    position: absolute;
    top: 440px;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--heading--8f837 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 850px;
    text-align: center;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--description--30cd2 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 789px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.ArtificialIntelligence-module--Vector--755d8 {
    position: relative;
}

.ArtificialIntelligence-module--Vector--755d8 img {
    left: 407px;
    position: absolute;
    top: 600px;
    z-index: 11;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--cardWrapper--51579 {
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--cardWrapper--51579 .ArtificialIntelligence-module--card--ac6ec {
    background: #fff;
    border-radius: 8px;
    min-height: 356px;
    outline: 1px solid #e9e9e9;
    padding: 36px;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--cardWrapper--51579 .ArtificialIntelligence-module--card--ac6ec img {
    margin-bottom: 18px;
    max-width: 60px;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--cardWrapper--51579 .ArtificialIntelligence-module--card--ac6ec h3 {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 16px;
}

.ArtificialIntelligence-module--subservice--bedd2 .ArtificialIntelligence-module--cardWrapper--51579 .ArtificialIntelligence-module--card--ac6ec p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
    max-width: 474px;
    min-height: 196px;
    text-align: left;
}

.PartnerWith-module--subservice--99d22 {
    background: #fafafa;
    padding: 100px 0;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--heading--23615 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 866px;
    text-align: center;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--description--cab16 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--cardWrapper--c64e2 {
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--cardWrapper--c64e2 .PartnerWith-module--card--049dc {
    width: auto;
    background: #fff;
    border-radius: 8px;
    margin: 1px;
    min-height: 270px;
    min-width: 310px;
    padding: 70px 24px 47px;
    text-align: center;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--cardWrapper--c64e2 .PartnerWith-module--card--049dc img {
    max-width: 60px;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--cardWrapper--c64e2 .PartnerWith-module--card--049dc h3 {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 auto;
    max-width: 212px;
    padding-top: 24px;
}

.PartnerWith-module--subservice--99d22 .PartnerWith-module--cardWrapper--c64e2 .PartnerWith-module--card--049dc .PartnerWith-module--cardDec--435e2 {
    color: #828282;
    cursor: default;
    display: none;
    font-family: inherit;
    font-size: 13px;
    margin: 0;
    max-width: 270px;
    padding-top: 10px;
    text-align: center;
}

.ArtificialIntelligence-module--dots4--c1eba {
    position: relative;
}

.ArtificialIntelligence-module--dots4--c1eba img {
    bottom: -131px;
    position: absolute;
    right: 0;
    z-index: -1;
}

.ArtificialIntelligence-module--dotscard--9ce0e {
    position: relative;
}

.ArtificialIntelligence-module--dotscard--9ce0e img {
    bottom: 33rem;
    position: absolute;
    right: 13rem;
    z-index: -1;
}

.SmarterFuture-module--gotProject--0e5b7 {
    background-image: url('/src/images/got-project.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 758px;
    padding: 100px 0;
}

.SmarterFuture-module--gotProject--0e5b7 .SmarterFuture-module--form--354cc {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0,0,0,.04);
    max-width: 1281px;
    min-height: 558px;
}

.SmarterFuture-module--projectForm--b4a6d {
    display: flex;
}

.SmarterFuture-module--gotProject--0e5b7 .SmarterFuture-module--form--354cc .SmarterFuture-module--formData--5e68f {
    padding: 53px 64px;
}

.SmarterFuture-module--gotProject--0e5b7 .SmarterFuture-module--form--354cc .SmarterFuture-module--formData--5e68f h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    max-width: 680px;
}

.checkForm .form-control, .formContent1 .form-control {
    background-clip: padding-box;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}
.formContent1 .form-control {
    background-color: #ecf4ff!important;
    border-radius: 0.75rem;
    height: auto;
}

.SmarterFuture-module--gotProject--0e5b7 .SmarterFuture-module--form--354cc .SmarterFuture-module--formData--5e68f p {
    color: #828282;
    font-size: 20px;
    font-weight: 400;
}

.SmarterFuture-module--briefSec--7d5ae {
    background: rgba(236,244,255,.5);
    border-radius: 0 12px 12px 0;
    max-width: 472px;
    padding: 53px 64px 0;
    position: relative;
}

.SmarterFuture-module--briefSec--7d5ae .SmarterFuture-module--brief--18a9d h3 {
    font-size: 20px;
    font-weight: 700;
}

.SmarterFuture-module--containerIntro--3e17c {
    margin-bottom: 45px;
    margin-top: 30px;
    padding-left: 24px;
}

.SmarterFuture-module--briefDot--0fd63 {
    background-color: #7388a5;
    border-radius: 50%;
    color: #7388a5;
    height: 5px;
    left: 64px;
    margin-top: 11px;
    position: absolute;
    width: 5px;
}

.SmarterFuture-module--briefSec--7d5ae .SmarterFuture-module--brief--18a9d h4 {
    color: #212529;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding-top: 20px;
}

.SmarterFuture-module--containerIntro--3e17c h4,.SmarterFuture-module--containerIntro--3e17c p{
    color: #7388a5;
    display: inline;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    max-width: 337px;
    padding-top: 20px;
}

.DevelopmentProcess-module--Ideation--82e57 {
    padding: 100px 0;
    position: relative;
}

@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--dots--96fc4 {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--dots--96fc4 {
    left: 12rem;
    position: absolute;
    top: 22rem;
}

@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--rec--0e862 {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--rec--0e862 {
    position: absolute;
    top: 11rem;
}

@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--circle--a4849 {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--circle--a4849 {
    position: absolute;
    right: 0;
    top: 11rem;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--heading--f2b36 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 830px;
    padding-bottom: 24px;
    text-align: center;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--dec--ccd94 {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto 25px;
    text-align: center;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 {
    align-items: center;
    display: flex;
    position: relative;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--leftText--e3921 {
    left: -25px;
    position: absolute;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards--58c73 {
    max-width: 328px;
    padding: 25px;
    position: relative;
    text-align: right;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--peakNumber2--ad068 {
    align-items: center;
    display: flex;
    justify-content: end;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--peakNumber2--ad068 .DevelopmentProcess-module--serial2--50a96 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards--58c73 h3 {
    font-size: 20px;
    font-weight: 700;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards--58c73 p {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--closeUp--ed5a9 {
    height: 580px;
    margin: 0 auto;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--rightText--c92b5 {
    position: absolute;
    right: -25px;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards2--10fa2 {
    max-width: 328px;
    padding: 25px;
    position: relative;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--peakNumber--1e7b3 {
    align-items: center;
    display: flex;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--peakNumber--1e7b3 .DevelopmentProcess-module--serial2--50a96 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards2--10fa2 h3 {
    font-size: 20px;
    font-weight: 700;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards2--10fa2 p {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cardsData--0aae5 .DevelopmentProcess-module--cards2--10fa2 {
    max-width: 328px;
    padding: 25px;
    position: relative;
}

.DevelopmentProcess-module--bannerBtn--fb3fa {
    margin-top: 60px;
    text-align: center;
}

@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--dots2--3d0ed {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--dots2--3d0ed {
    bottom: 22rem;
    position: absolute;
    right: 14rem;
}

@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cir--526d5 {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--cir--526d5 {
    bottom: 3rem;
    left: 39rem;
    position: absolute;
}


@media (max-width: 1600px){
.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--arrow2--69591 {
    display: none;
}
}

.DevelopmentProcess-module--Ideation--82e57 .DevelopmentProcess-module--arrow2--69591 {
    bottom: 3rem;
    position: absolute;
    right: 39rem;
}

.DevelopmentProcess-module--bannerBtn--fb3fa .DevelopmentProcess-module--btn_white1_border--223c0 {
    background: #2176ff;
    border: none;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.HireAI-module--hireCyber--9f527 {
    background: #fafafa;
    padding: 100px 0;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--hire--3fd4e {
    text-align: center;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--hire--3fd4e h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 530px;
    padding-bottom: 24px;
    padding-top: 36px;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--hire--3fd4e .HireAI-module--description--5cdaa {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 525px;
}

.HireAI-module--bannerBtn--fcdc9 {
    margin-top: 40px;
    text-align: center;
}

@media screen and (min-width: 1281px){
.HireAI-module--bannerBtn--fcdc9 .HireAI-module--btn_white1_border--731ec {
    width: unset;
}
}

.HireAI-module--bannerBtn--fcdc9 .HireAI-module--btn_white1_border--731ec {
    background: #2176ff;
    border: none;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 {
    background: #fff;
    border-radius: 12px;
    padding: 0 30px 0 29px;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 .HireAI-module--talent--d426a {
    align-items: flex-start;
    border-bottom: 1px solid #efeeee;
    display: flex;
    padding: 28px 0;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 .HireAI-module--talent--d426a .HireAI-module--tick--14744 {
    margin-top: 5px;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 .HireAI-module--talent--d426a .HireAI-module--text--b1301 {
    margin-left: 12px;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 .HireAI-module--talent--d426a h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    text-transform: capitalize;
}

.HireAI-module--hireCyber--9f527 .HireAI-module--cards--8fe83 .HireAI-module--talent--d426a p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding-top: 8px;
}

@media screen and (min-width: 1280px){
.HireAI-module--bannerBtn2--cebe1 {
    display: none;
}
}

.HireAI-module--bannerBtn2--cebe1 {
    margin-top: 30px;
    text-align: center;
}

.HireAI-module--bannerBtn2--cebe1 .HireAI-module--btn_white1_border--731ec {
    background: #2176ff;
    border: none;
    border-radius: 50rem;
    color: #fff!important;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Toolkit-module--advance--df11c {
    background-repeat: no-repeat;
    background: url('/src/images/numberBg.png');
    background-color: hsla(0,0%,97%,.7);
    background-size: cover;
    position: relative;
}

.Toolkit-module--techBg--71d88 {
    padding: 100px 0;
}

.Toolkit-module--heading--ccbb3 {
    font-size: 39px;
    font-weight: 700;
    margin: 0 auto;
    text-align: center;
}

.Toolkit-module--techBg--71d88 .Toolkit-module--description--67577 {
    font-size: 16px;
    font-weight: 400;
    margin: 24px auto 30px;
    max-width: 860px;
    text-align: center;
}

.Toolkit-module--techIcon--f443f {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Toolkit-module--iconssCard--9ed24 {
    background: #fff;
    border-radius: 8px;
    margin: 15px;
    z-index: 1;
}

.Toolkit-module--technologyIcon--241cc {
    height: 161px;
    padding: 12px;
    text-align: center;
    width: 216px;
}

.Toolkit-module--technologyIcon--241cc .Toolkit-module--techImg--e70eb {
    border-radius: 4px;
}
.Toolkit-module--techImg--e70eb {
    align-items: center;
    border-radius: 80px;
    display: flex;
    height: 88px;
    justify-content: center;
    text-align: center;
}

.Toolkit-module--techImg--e70eb img {
    width: 75%;
}

.Toolkit-module--technologyIcon--241cc h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding-top: 10px;
}

.Faqs2-module--section--b0065 {
    background-color: #fafafa;
    padding: 100px 0;
}

.Faqs2-module--section--b0065 * {
    background-color: #fafafa!important;
}

.Faqs2-module--section--b0065 .Faqs2-module--head--b1ec9 {
    color: #212529;
    cursor: default;
    /* font-family: Public Sans; */
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 148.5%;
    margin-bottom: 47px;
    text-align: center;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px;
}
.faqsAccordian .card-header {
    border-bottom: unset;
    padding: 25px 0;
}

.Faqs2-module--section--b0065 .Faqs2-module--quesColor--4b2cd {
    color: #333;
}

.Faqs2-module--section--b0065 .Faqs2-module--faqsNum--44993 {
    align-items: center;
    display: flex;
}

@media (min-width: 767px){
.Faqs2-module--section--b0065 .Faqs2-module--faqsNum--44993 .Faqs2-module--serial2--88c80 {
    display: none;
}
}

.Faqs2-module--section--b0065 .Faqs2-module--faqsNum--44993 .Faqs2-module--serial2--88c80 {
    margin-right: 6px;
}

.Faqs2-module--section--b0065 .Faqs2-module--faqsNum--44993 .Faqs2-module--serial--98a5e {
    left: -55px;
    padding-right: 20px;
}
.Faqs2-module--section--b0065 * {
    background-color: #fafafa!important;
}

.Faqs2-module--section--b0065 .Faqs2-module--faqsNum--44993 .Faqs2-module--faqsSub--2f843 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}

.Faqs2-module--section--b0065 .Faqs2-module--textColor--e10b6 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 148.5%;
    padding: 0 20px 10px 39px;
}

.Faqs2-module--section--b0065 .Faqs2-module--textColor--e10b6 p {
    color: #828282;
    font-size: 14px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

