.Services-module--focused--c457c{
    background: linear-gradient(265deg, #1ca3e9, rgb(30, 19, 133));

}

.Services-module--serviceCard--27b94 img{
    display: block;
    height: 37px;
    margin: auto;
    width: 37px;
}
.Services-module--serviceCard--27b94.Services-module--focused--c457c .Services-module--heading--f9c58{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
    min-width: 147px;
    padding-top: 10px;
}

.invertWhite{
    filter: brightness(0) invert(1);
}

.Services-module--serviceCard--27b94{
    align-items: center;
    background-color: #fff;
    border: 1px solid #f7f7f7;
    border-radius: 12px;
    box-shadow: 16px 16px 84px 0 rgba(0,0,0,.03);
    cursor: pointer;
    display: flex;
    height: 130px;
    justify-content: center;
    margin-bottom: 20px;
    min-width: 100%;
    padding: 30px;
    text-align: center;
}

.Services-module--heading--f9c58{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0;
    min-width: 147px;
    padding-top: 10px;
}

.main-heading-36 {
    font-size: 36px;
    font-weight: bold;
}

section{
    padding-top: 200px;
}

.pr-4{
    padding-right: 1.5rem!important;
}

.Services-module--para--5ad1a {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

@media (max-width: 765px){
section {
    padding: 60px 0;
}
}

@media (max-width: 578px){
.Industries-module--sliderContainer--8cd67 {
    padding: 150px 0 0;
}
}