.Banner-module--banner--956ec {
    background-image: url(https://invozone.com/static/hero-bg-24bc129718e948c88bd6edeb3ef17a3a.webp);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 155px 0;
    position: relative;
    width: 100%;
}

.Banner-module--banner--956ec .Banner-module--heading--2ad20 .Banner-module--bannerHeading--473e6 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    margin: 0;
    max-width: 616px;
}

.Banner-module--banner--956ec .Banner-module--heading--2ad20 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 155%;
    margin: 0;
    max-width: 568px;
    padding-top: 5px;
}

.Banner-module--bannerBtn--c7770 {
    padding-top: 36px;
}

.Banner-module--bannerBtn--c7770 a {
    color: #2476fc;
}

.Banner-module--btn_white2_border--d325b {
    background-color: #fff !important;
    border: none;
    border-radius: 50rem;
    color: #2176ff;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Banner-module--BannerImage--e9290 {
    border-radius: 24px;
}

.Banner-module--BannerImage--e9290 {
    border-radius: 24px;
}

@media screen and (min-width: 1281px) {
    .Lead-module--Vector3--7fcf3 {
        display: none;
    }
}

.Lead-module--Vector3--7fcf3 {
    position: absolute;
    top: 21rem;
}

.Lead-module--subservice--34d32 {
    padding: 100px 0;
    position: relative;
}

.Lead-module--subservice--34d32 .Lead-module--heading--5dd5d {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 850px;
    text-align: center;
}

.Lead-module--subservice--34d32 .Lead-module--description--6b947 {
    color: #212529;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -.015em;
    line-height: 26px;
    margin: 0 auto;
    max-width: 789px;
    padding-top: 24px;
    text-align: left;
    text-align: center;
}

.Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad {
    margin-top: 60px;
    position: relative;
    z-index: 99;
}

@media screen and (min-width: 1281px) {
    .Lead-module--dots--403d6 {
        display: none;
    }
}

.Lead-module--dots--403d6 {
    left: 6rem;
    position: absolute;
    top: 29rem;
}

.Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 {
    background: #fff;
    border-radius: 8px;
    min-height: 416px;
    outline: 1px solid #e9e9e9;
    padding: 36px 31px 23px 34px;
}

.Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 img {
    margin-bottom: 18px;
    max-width: 60px;
}

.Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 h3 {
    cursor: default;
    font-family: inherit;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin: 0 0 16px;
}

.Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 p {
    color: #828282;
    cursor: default;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0;
    max-width: 474px;
    min-height: 196px;
    text-align: left;
}

.Elevate-module--build--29f28 {
    padding-bottom: 0;
    padding-top: 0;
}

.Elevate-module--build--29f28 .Elevate-module--buildInner--d05d5 {
    background: linear-gradient(180deg, #fafafa, hsla(0, 0%, 98%, 0) 87.5%);
    border-radius: 24px;
    padding: 100px 110px;
}

.Elevate-module--heading--1a583 {
    cursor: default;
    font-size: 39px;
    font-weight: 700;
    margin: 0 auto;
    max-width: 806px;
    padding-bottom: 24px;
    text-align: center;
}

.Elevate-module--description--9c12f {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin: 0 auto;
    max-width: 706px;
    padding-bottom: 40px;
    text-align: center;
}

.Elevate-module--cardData--1765c {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 30px;
    justify-content: center;
}

.Elevate-module--step--6adc2 .Elevate-module--step01--9cce8 {
    color: #828282;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 8px;
    padding-left: 108px;
}

.Elevate-module--card--8c0bd {
    background: #fff;
    border: 1px solid #2176ff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    max-width: 317px;
    min-height: 340px;
    padding: 42px 21px 26px;
    position: relative;
    width: 317px;
}

.Elevate-module--card--8c0bd .Elevate-module--bgImg--37917 {
    align-items: center;
    background: #fff;
    border: 1px solid #2176ff;
    border-radius: 12px;
    display: flex;
    height: 72px;
    justify-content: center;
    left: 24px;
    position: absolute;
    top: -35px;
    width: 72px;
}

.Elevate-module--card--8c0bd h3 {
    color: #212529;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
}

.Elevate-module--card--8c0bd p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    line-height: 26px;
    margin-bottom: 0;
}

.Feel-module--gotProject--5a7fd {
    background-image: url(https://invozone.com/static/gotproject-e824c7362178bf4c7435d626cf85f4b1.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 758px;
    padding: 100px 0;
}

.Feel-module--gotProject--5a7fd .Feel-module--form--44d09 {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    max-width: 1281px;
    min-height: 558px;
}

.Feel-module--projectForm--e5b8b {
    display: flex;
}

.Feel-module--gotProject--5a7fd .Feel-module--form--44d09 .Feel-module--formData--e321d {
    padding: 53px 64px;
}

.Feel-module--gotProject--5a7fd .Feel-module--form--44d09 .Feel-module--formData--e321d h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    max-width: 680px;
}

.btn_white_border_yellow {
    border-radius: 50rem;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font: "400 13.3333px Arial";
    font-weight: 700;
    line-height: 1.5;
    min-width: 100px;
    overflow: hidden;
    padding: 0.5rem 2rem;
    position: relative;
    text-align: center;
    transition: all .3s;
    vertical-align: middle;
    z-index: 99;
}

.Feel-module--gotProject--5a7fd .Feel-module--form--44d09 .Feel-module--formData--e321d p {
    color: #828282;
    font-size: 20px;
    font-weight: 400;
}

.Feel-module--briefSec--688a7 {
    background: rgba(236, 244, 255, .5);
    border-radius: 0 12px 12px 0;
    max-width: 472px;
    padding: 53px 53px 8px 64px;
    position: relative;
}

.Feel-module--briefSec--688a7 .Feel-module--brief--a3667 h3 {
    font-size: 20px;
    font-weight: 700;
}

.Feel-module--containerIntro--c2507 {
    margin-bottom: 22px;
    margin-top: 30px;
    padding-left: 24px;
}

.Feel-module--briefDot--10774 {
    background-color: #7388a5;
    border-radius: 50%;
    color: #7388a5;
    height: 5px;
    left: 64px;
    margin-top: 11px;
    position: absolute;
    width: 5px;
}

.Feel-module--briefSec--688a7 .Feel-module--brief--a3667 h4 {
    color: #212529;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding-top: 20px;
}

.Feel-module--containerIntro--c2507 h4,
.Feel-module--containerIntro--c2507 p {
    color: #7388a5;
    display: inline;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    max-width: 337px;
    padding-top: 20px;
}

@media (max-width: 1280px) {
    .Banner-module--banner--956ec {
        background-image: url('https://invozone.com/static/tablet-hero-bg-1ff4417747a177b3e46757ece643e2b6.webp');
        padding: 60px 0;
    }

    .Banner-module--banner--956ec .Banner-module--heading--2ad20 {
        margin-top: 0;
        text-align: center;
    }

    .Banner-module--banner--956ec .Banner-module--heading--2ad20 p {
        margin: 0 auto;
        max-width: 556px;
    }

    .Banner-module--bannerBtn--c7770 {
        text-align: center;
    }

    .Banner-module--bnrImg--0c9c6 {
        text-align: center;
    }

    .Banner-module--BannerImage--e9290 {
        border-radius: 10px;
        margin-top: 30px;
        max-width: 605px;
        text-align: center;
    }

    .Lead-module--subservice--34d32 {
        padding: 60px 0 0;
    }
    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 {
        box-shadow: 10px 30px 55px 1px rgba(0,0,0,.04);
        outline: none;
        padding: 24px 24px 17px;
    }
    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 p {
        min-height: 224px;
    }
    .Elevate-module--build--29f28 {
        padding-top: 60px;
    }
    .Elevate-module--pad--f45d8 {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .Elevate-module--build--29f28 .Elevate-module--buildInner--d05d5 {
        padding: 60px 0;
    }
    .Elevate-module--heading--1a583 {
        max-width: 642px;
    }
    .Elevate-module--description--9c12f {
        max-width: 642px;
    }
    .Feel-module--gotProject--5a7fd {
        padding: 60px 0;
    }
    .Feel-module--projectForm--e5b8b {
        display: unset;
        flex-wrap: wrap;
    }
    .Feel-module--gotProject--5a7fd .Feel-module--form--44d09 .Feel-module--formData--e321d {
        padding: 24px;
    }
    .Feel-module--gotProject--5a7fd .Feel-module--form--44d09 .Feel-module--formData--e321d h2 {
        text-align: left;
    }
    .Feel-module--briefSec--688a7 {
        max-width: unset;
        padding: 30px;
    }
    .Feel-module--containerIntro--c2507 {
        margin-bottom: unset;
    }
    .Feel-module--briefDot--10774 {
        left: 30px;
    }
    .Feel-module--containerIntro--c2507 h4, .Feel-module--containerIntro--c2507 p {
        max-width: 100%;
    }
}

@media (max-width: 1200px) {
    .Banner-module--banner--956ec .Banner-module--heading--2ad20 .Banner-module--bannerHeading--473e6 {
        max-width: 659px;
        min-width: 0;
    }
    
}

@media screen and (max-width: 992px) {
    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 p {
        max-width: 100%;
    }
    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 {
        max-width: unset;
        min-height: 442px;
    }
    .Lead-module--subservice--34d32 .Lead-module--heading--5dd5d {
        margin-bottom: 10px;
    }

    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad {
        margin-top: 40px;
    }
}

@media (max-width: 767px) {
    .Feel-module--submitBtn--70375 {
        text-align: left;
    }
    .Elevate-module--description--9c12f {
        max-width: 350px;
    }
    .Elevate-module--heading--1a583 {
        max-width: 350px;
    }
    .Lead-module--subservice--34d32 .Lead-module--cardWrapper--65fad .Lead-module--card--5a503 {
        max-width: unset;
    }
    .Banner-module--btn_white2_border--d325b {
        width: unset !important;
    }

    .Banner-module--banner--956ec {
        background-image: url('https://invozone.com/static/mobile-hero-bg-f02aa484634309bdd28c2fc28d1d4479.webp');
    }
}

@media (max-width: 600px) {
    .Banner-module--bannerBtn--c7770 {
        text-align: center;
    }

    .Banner-module--banner--956ec .Banner-module--heading--2ad20 {
        text-align: center;
    }

    .Banner-module--banner--956ec .Banner-module--heading--2ad20 .Banner-module--bannerHeading--473e6 {
        margin: auto;
        font-size: 36px;
        line-height: 50px;
        max-width: 352px;
    }
    .Feel-module--gotProject--5a7fd {
        background-image: url('https://invozone.com/static/gotBg-5aba1867dcccdb9eae3f3c196772ec98.svg');
        padding-bottom: 60px;
        padding-top: 60px;
    }
    .Feel-module--briefSec--688a7 {
        border-radius: 0 0 12px 12px;
        padding: 24px;
    }
    .Feel-module--containerIntro--c2507 {
        margin-bottom: unset;
        padding-left: 24px;
    }
}