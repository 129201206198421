.Banner-module--careers--4de67 {
    background-color: #f3f8ff;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 34px;
    padding-top: 140px;
}

.Banner-module--card--4f500 {
    border-radius: 25px;
    color: #fff;
    padding-right: 40px;
    width: 100%;
}

.ContactForm-module--contactContnt--ff721 {
    color: #000;
}

.ContactForm-module--contactContnt--ff721 h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
}

.ContactForm-module--contactContnt--ff721 p {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.ContactForm-module--staffCheck--6db88 {
    align-items: center;
    display: flex;
}

.ContactForm-module--staffCheck--6db88 label {
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 22px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-bottom: 18px;
    padding-left: 30px;
    padding-top: 4px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}


.ContactForm-module--staffCheck--6db88 .ContactForm-module--checkmark--cdcad {
    border: 1px solid #828282;
    border-radius: 30px;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: -5px;
    width: 20px;
}

.ContactForm-module--helpTip--c507a {
    background-color: #e9e9ea;
    border-radius: 50%;
    cursor: default;
    font-size: 14px;
    height: 24px;
    line-height: 26px;
    margin-bottom: 14px;
    margin-left: 6px;
    position: relative;
    text-align: center;
    width: 24px;
}

.ContactForm-module--helpTip--c507a:before {
    color: #fff;
    content: "?";
    font-weight: 700;
}

.ContactForm-module--helpTip--c507a p {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNDIiIGhlaWdodD0iNTMiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzNDIgNTMiPjxtYXNrIGlkPSJwcmVmaXhfX2EiIGZpbGw9IiNmZmYiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxIDBDMTQuMzczIDAgOSA1LjM3MyA5IDEydjEuODY2bC05IDUuMTk2IDkgNS4xOTZWNDFjMCA2LjYyNyA1LjM3MyAxMiAxMiAxMmgzMDljNi42MjcgMCAxMi01LjM3MyAxMi0xMlYxMmMwLTYuNjI3LTUuMzczLTEyLTEyLTEySDIxWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9tYXNrPjxwYXRoIGZpbGw9IiNGMEY2RkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxIDBDMTQuMzczIDAgOSA1LjM3MyA5IDEydjEuODY2bC05IDUuMTk2IDkgNS4xOTZWNDFjMCA2LjYyNyA1LjM3MyAxMiAxMiAxMmgzMDljNi42MjcgMCAxMi01LjM3MyAxMi0xMlYxMmMwLTYuNjI3LTUuMzczLTEyLTEyLTEySDIxWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggZmlsbD0iIzIxNzZGRiIgZD0ibTkgMTMuODY2LjUuODY2LjUtLjI4OXYtLjU3N0g5Wm0tOSA1LjE5Ni0uNS0uODY2LTEuNS44NjYgMS41Ljg2Ni41LS44NjZabTkgNS4xOTZoMXYtLjU3N2wtLjUtLjI4OS0uNS44NjZaTTEwIDEyYzAtNi4wNzUgNC45MjUtMTEgMTEtMTF2LTJDMTMuODItMSA4IDQuODIgOCAxMmgyWm0wIDEuODY2VjEySDh2MS44NjZoMlpNLjUgMTkuOTI4bDktNS4xOTZMOC41IDEzbC05IDUuMTk2IDEgMS43MzJabTkgMy40NjQtOS01LjE5Ni0xIDEuNzMyIDkgNS4xOTYgMS0xLjczMlpNMTAgNDFWMjQuMjU4SDhWNDFoMlptMTEgMTFjLTYuMDc1IDAtMTEtNC45MjUtMTEtMTFIOGMwIDcuMTggNS44MiAxMyAxMyAxM3YtMlptMzA5IDBIMjF2MmgzMDl2LTJabTExLTExYzAgNi4wNzUtNC45MjUgMTEtMTEgMTF2MmM3LjE4IDAgMTMtNS44MiAxMy0xM2gtMlptMC0yOXYyOWgyVjEyaC0yWk0zMzAgMWM2LjA3NSAwIDExIDQuOTI1IDExIDExaDJjMC03LjE4LTUuODItMTMtMTMtMTN2MlpNMjEgMWgzMDl2LTJIMjF2MloiIG1hc2s9InVybCgjcHJlZml4X19hKSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 3px;
    bottom: -41px;
    color: #2176ff;
    display: none;
    font-size: 13px;
    left: 26px;
    line-height: 1.4;
    padding: 10px 27px;
    position: absolute;
    text-align: left;
    width: 350px;
    z-index: 999;
}

.checkForm .form-control {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 0.75rem;
    height: calc(1.5em + 1rem + 2px);
}

.checkForm .form-control,
.formContent1 .form-control {
    background-clip: padding-box;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.position-absolute {
    position: absolute !important;
}

.invalid-feedback {
    color: #dc3545;
    display: none;
    font-size: 80%;
    margin-top: 0.25rem;
    width: 100%;
}

.react-tel-input .form-control {
    background: #fff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    font-size: 14px;
    height: 35px;
    letter-spacing: .01rem;
    line-height: 25px;
    margin-bottom: 0 !important;
    margin-left: 0;
    margin-top: 0 !important;
    outline: none;
    padding-left: 48px;
    position: relative;
    width: 300px;
}

.phoneSelect .form-control {
    background-clip: padding-box !important;
    background-color: #fff !important;
    border: 1px solid #495057 !important;
    border-radius: 0.75rem !important;
    color: #495057 !important;
    display: block !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    height: calc(1.5em + 1rem + 2px) !important;
    line-height: 1.5 !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100% !important;
}

.react-tel-input .flag-dropdown {
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    bottom: 0;
    padding: 0;
    position: absolute;
    top: 0;
}

.react-tel-input .flag-dropdown {
    background-color: unset !important;
    border: 1px solid #495057;
    border-bottom-left-radius: 0.75rem !important;
    border-right-color: transparent !important;
    border-top-left-radius: 0.75rem !important;
    box-shadow: none;
    outline: none !important;
}

.react-tel-input .selected-flag {
    border-radius: 3px 0 0 3px;
    height: 100%;
    outline: none;
    padding: 0 0 0 8px;
    position: relative;
    width: 38px;
}

.react-tel-input .selected-flag .flag {
    margin-top: -5px;
    position: absolute;
    top: 50%;
}

.react-tel-input .flag {
    background-image: url('/src/images/flags.png');
    height: 11px;
    width: 16px;
}

.react-tel-input .selected-flag .arrow {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
    height: 0;
    left: 20px;
    margin-top: -2px;
    position: relative;
    top: 50%;
    width: 0;
}

.react-tel-input .selected-flag .arrow {
    background-image: url('/src/images/arrow.svg');
    background-size: 100% 100% !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    height: 100% !important;
    left: 27px !important;
    margin-top: 0 !important;
    transform: translate(-50%, -50%);
    width: 100% !important;
}

.checkForm .form-control {
    background-color: #fff;
    border: 1px solid #495057 !important;
    border-radius: 0.75rem;
    height: calc(1.5em + 1rem + 2px);
}

.checkForm .form-control,
.formContent1 .form-control {
    background-clip: padding-box;
    color: #495057;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.css-2b097c-container {
    position: relative;
    box-sizing: border-box;
}

.css-7pg0cj-a11yText {
    z-index: 9999;
    border: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    white-space: nowrap;
}

.checkForm .css-a33x3s-control {
    background-color: #fff;
    border: 1px solid #495057;
}

.css-a33x3s-control {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(248, 248, 248);
    border-radius: 0.75rem;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 43px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(0, 0, 0);
    border: 0px;
    outline: 0px !important;
}

.css-1hwfws3 {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.css-1wa3eu0-placeholder {
    color: rgb(128, 128, 128);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
}

.css-1g6gooi {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    visibility: visible;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.css-1wy0on6 {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.css-xzu46z-indicatorContainer {
    color: rgb(0, 0, 0);
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;
}

.css-8mmkcg {
    display: inline-block;
    fill: currentcolor;
    line-height: 1;
    stroke: currentcolor;
    stroke-width: 0;
}

.checkForm textarea.form-control {
    height: 120px;
}

.checkparent {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.ContactForm-module--checkText--98a34 {
    color: #000 !important;
    font-size: 14px;
}

.ContactForm-module--btnSend--095e7 {
    background: transparent;
    background: #2176ff;
    border: 1px solid var(--primary) !important;
    border-radius: 50rem;
    color: #fff !important;
    display: inline-block;
    font-style: normal;
    font: "400 13.3333px Arial";
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin-top: 5px;
    min-width: 100px;
    overflow: hidden;
    padding: 13px 35px;
    position: relative;
    text-align: center;
    text-align: right;
    transition: all .3s;
    vertical-align: middle;
}

.InvozoneNumber-module--number--f6573 {
    padding: 100px 0;
    position: relative;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cir--8d9e3 {
    bottom: 120px;
    position: absolute;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--rec--f52fb {
    bottom: 130px;
    position: absolute;
    right: 0;
}

@media screen and (max-width: 1400px) {
    .InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cir--8d9e3 {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .InvozoneNumber-module--number--f6573 .InvozoneNumber-module--rec--f52fb {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .InvozoneNumber-module--number--f6573 .InvozoneNumber-module--dots--77f74 {
        display: none;
    }
}

.InvozoneNumber-module--number--f6573 h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 679px;
    padding-bottom: 60px;
    text-align: center;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--dots--77f74 {
    position: absolute;
    right: 200px;
    top: 190px;
}

@media screen and (min-width: 600px) {
    .InvozoneNumber-module--number--f6573 .InvozoneNumber-module--dots2--29ec0 {
        display: none;
    }
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--dots2--29ec0 {
    position: absolute;
    right: -5px;
    top: 32rem;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cardsData--7db46 .InvozoneNumber-module--cards--a3883 {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    display: grid;
    padding: 30px;
    text-align: center;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cardsData--7db46 .InvozoneNumber-module--cards--a3883 img {
    margin: 0 auto;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cardsData--7db46 .InvozoneNumber-module--cards--a3883 span {
    font-size: 39px;
    font-weight: 700;
    margin-top: 20px;
}

.InvozoneNumber-module--number--f6573 .InvozoneNumber-module--cardsData--7db46 .InvozoneNumber-module--cards--a3883 h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 auto;
    max-width: 163px;
}

.Offices-module--number--fa14b {
    background: #f3f8ff;
    padding: 100px 0;
}

.Offices-module--number--fa14b h2 {
    font-size: 39px;
    font-weight: 700;
    line-height: 52px;
    margin: 0 auto;
    max-width: 679px;
    padding-bottom: 12px;
    text-align: center;
}

.Offices-module--number--fa14b p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
}

.Offices-module--number--fa14b .Offices-module--cardsData--d327d .Offices-module--cards--aac2b {
    background: #fff;
    border-radius: 12px;
    box-shadow: 10px 30px 55px 1px rgba(0, 0, 0, .04);
    display: grid;
    padding: 30px;
    text-align: center;
}

.Offices-module--number--fa14b .Offices-module--cardsData--d327d .Offices-module--cards--aac2b img {
    margin: 0 auto;
}

.Offices-module--number--fa14b .Offices-module--cardsData--d327d .Offices-module--cards--aac2b h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}

.Offices-module--number--fa14b .Offices-module--cardsData--d327d .Offices-module--cards--aac2b p {
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .Banner-module--careers--4de67 {
        background: unset;
        padding-bottom: unset;
        padding-top: unset;
    }

    .Banner-module--card--4f500 {
        padding-right: unset;
    }
}

@media (max-width: 991px) {
    .Banner-module--illustration--c072b {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .ContactForm-module--contactContnt--ff721 h1 {
        max-width: 272px;
    }
    .ContactForm-module--contactContnt--ff721 p {
        max-width: 272px;
    }
    .ContactForm-module--helpTip--c507a {
        position: absolute;
        right: 20px;
    }
    .ContactForm-module--helpTip--c507a p {
        background: #f0f6ff;
        border: 1px solid #2176ff;
        bottom: -27px;
        display: none;
        left: unset;
        padding: 10px 12px;
        right: 30px;
        width: 282px;
    }
}